import React, { useState, useEffect } from 'react';
import { Report } from 'cccisd-laravel-report';
import Loader from 'cccisd-loader';
import LogicModelPage from './LogicModelPage.js';
import style from './style.css';
import { sortProblemsByPriority, filterArchivedProblems, sortProblemList } from '../ProblemBox/helpers.js';
import { useMetricspawnQuery, useAssignmentProgress } from 'js/queries';
import IconInfo from 'cccisd-icons/info2';

const LogicModelReport = props => {
    const [problems, setProblems] = useState([]);

    const metricspawnQuery = useMetricspawnQuery();
    const metricspawn = metricspawnQuery?.data?.descendantRoles?.metricspawn?.pawn;
    const assignmentProgressQuery = useAssignmentProgress('assignmentProgress', metricspawn?.pawnId);

    useEffect(() => {
        const savedProblemOrder = metricspawnQuery?.data?.fields?.problemOrder;
        if (savedProblemOrder) {
            const problemOrderList = JSON.parse(savedProblemOrder);
            const assignmentProgressListSorted = sortProblemList(problemOrderList, assignmentProgressQuery.data);
            setProblems(filterArchivedProblems(assignmentProgressListSorted));
            return;
        }
        setProblems(filterArchivedProblems(sortProblemsByPriority(assignmentProgressQuery.data)));
    }, [
        metricspawnQuery.isLoading,
        metricspawnQuery.data,
        assignmentProgressQuery.isLoading,
        assignmentProgressQuery.data,
    ]);

    const isLoading = metricspawnQuery.isLoading || assignmentProgressQuery.isLoading;

    const allQueryDataReturned = metricspawnQuery.data && assignmentProgressQuery.data;

    if (isLoading) {
        return <Loader loading />;
    }

    if (allQueryDataReturned) {
        return (
            <div className={style.reportWrapper}>
                {problems.length > 0 ? (
                    <Report height="1120px" width="1120px" downloadFilename="e-GTO_Logic_Model" orientation="landscape">
                        {problems.map(ap => {
                            return (
                                <LogicModelPage
                                    deployment={ap.deployment}
                                    problem={ap.problem}
                                    metricspawn={props.metricspawn}
                                    key={JSON.stringify(ap)}
                                />
                            );
                        })}
                    </Report>
                ) : (
                    <div className={style.summaryText}>
                        <IconInfo /> No unarchived problems
                    </div>
                )}
            </div>
        );
    }
};

export default LogicModelReport;
