import React from 'react';
export default settings => {
    return ({ value, row }) => {
        return (
            <>
                {JSON.parse(value) ? (
                    <ul>
                        {JSON.parse(value).map((item, index) => {
                            return (
                                <li key={index} role="presentation">
                                    {item}
                                </li>
                            );
                        })}
                    </ul>
                ) : null}
            </>
        );
    };
};
