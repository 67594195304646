import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Tooltip from 'cccisd-tooltip';
import { BrowserOnly } from 'cccisd-laravel-report';
import { stepColors } from '../../PlanningBox/stepBoxes.js';
import style from './style.css';
import Logo from './egto-logo.svg';
import IconAlert from 'cccisd-icons/warning2';

const Fortress = window.cccisd.fortress;

const ReportHeader = props => {
    const badge = (title, stepNumber, badgeStyle) => {
        return (
            <div
                className={`label ${style.label}`}
                style={{
                    backgroundColor: stepColors[stepNumber],
                    marginRight: '0.3em',
                    ...badgeStyle,
                }}
            >
                {title}
            </div>
        );
    };

    const alertIcon = (
        <span style={{ color: '#c5002e', fontSize: '1.3em' }}>
            <IconAlert spaceRight />
        </span>
    );

    return (
        <div className={style.headerWrapper}>
            <div className={style.headerTop}>
                <div className={style.headerLeft}>
                    <img src={Logo} alt="" className={style.logo} />
                    <div className={style.title}>
                        <div className={style.groupName}>Logic Model</div>
                    </div>
                    {props.alerts && props.alerts.length > 0 && (
                        <BrowserOnly>
                            {props.alerts.map(x => {
                                return <Tooltip title={x.tooltip}>{alertIcon}</Tooltip>;
                            })}
                        </BrowserOnly>
                    )}
                </div>
                <div className={style.headerRight}>
                    <div>
                        <span className={style.subHeading}>VSO: </span>
                        <span style={{ color: '#353863' }}>
                            {Fortress.user.acting.group ? Fortress.user.acting.group.label : 'My VSO'}
                        </span>
                    </div>
                    <div>
                        <span className={style.subHeading}>Date: </span>
                        <span style={{ color: '#353863' }}>{moment(new Date()).format('MMMM Do, YYYY')}</span>
                    </div>
                </div>
            </div>
            <div className={style.headerBottom}>
                <div
                    style={{
                        fontSize: 'large',
                        textAlign: 'center',
                        backgroundColor: '#474ab0',
                        color: '#fff',
                        padding: '0.5em',
                    }}
                >
                    {badge('Step 1', '1', {
                        border: '1px solid #fff',
                        fontSize: '12px',
                    })}{' '}
                    Priority Problem: {props.problem.s1_problem_name}
                </div>
            </div>
        </div>
    );
};

ReportHeader.propTypes = {
    settings: PropTypes.object,
    filters: PropTypes.object,
    problem: PropTypes.object,
    alerts: PropTypes.array,
};

ReportHeader.defaultProps = {
    settings: {
        header: {
            title: 'GTO Intervention Comparison Report',
        },
    },
};

export default ReportHeader;
