import React from 'react';
import Changes from './Cell/changes.js';
import Tooltip from 'cccisd-tooltip';
import style from './style.css';
import IconInfo from 'cccisd-icons/info2';
import IconFit from 'cccisd-icons/rulers';
import IconCap from 'cccisd-icons/enlarge2';

const table = props => {
    const getInterventionData = () => {
        let data = [];
        const thereIsReportData = Object.keys(props.data).length > 0;
        const thereAreValidFilters = typeof props.filters.intervention === 'object';

        if (thereIsReportData) {
            if (thereAreValidFilters) {
                props.filters.intervention.forEach(i => {
                    data.push({ ...props.data[i.value], name: i.label });
                });
            }
            return data;
        }
        return data;
    };

    const data = getInterventionData();

    if (data.length < 1) {
        return (
            <div className="alert alert-info" role="alert">
                <IconInfo /> &nbsp;&nbsp; Select up to three interventions to see a comparison report.
            </div>
        );
    }

    return (
        <table className={style.tg} style={{ width: '100%' }}>
            <colgroup>
                <col style={{ width: '14%' }} />
                <col style={{ width: `${86 / data.length}%` }} />
                <col style={{ width: `${86 / data.length}%` }} />
                <col style={{ width: `${86 / data.length}%` }} />
            </colgroup>
            <thead>
                <tr>
                    <th className={style.tgHeading} />
                    {data[0] && <th className={style.tgHeading}>{data[0] && data[0].name}</th>}
                    {data[1] && <th className={style.tgHeading}>{data[1] && data[1].name}</th>}
                    {data[2] && <th className={style.tgHeading}>{data[2] && data[2].name}</th>}
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`}>
                        <Tooltip
                            title="Does the intervention fit with the target population's needs, demographics (e.g., gender, pay grade, race/ethnicity), or other important characteristics (e.g., education level, work schedule)?"
                            placement="right"
                        >
                            <div
                                className={`${style.icon} ${style.twoColumn}`}
                                style={{ width: '100%', borderRadius: '0', padding: '0.5em' }}
                            >
                                <div style={{ marginRight: '0.4em' }}>
                                    <IconFit />
                                </div>
                                <span style={{ fontSize: 'smaller', lineHeight: '1.2' }}>
                                    Is this a good FIT for your...
                                </span>
                            </div>
                            <div className={style.domainTitle}>
                                Target Population{' '}
                                <span className={style.tooltipIcon}>
                                    <IconInfo />
                                </span>
                            </div>
                        </Tooltip>
                    </td>
                    {data[0] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[0]} domain="target" />
                        </td>
                    )}
                    {data[1] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[1]} domain="target" />
                        </td>
                    )}
                    {data[2] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[2]} domain="target" />
                        </td>
                    )}
                </tr>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`}>
                        <Tooltip
                            title="Does the intervention fit with the community’s cultural norms, values, or other important characteristics (e.g., rural or urban, U.S. or international)?"
                            placement="right"
                        >
                            <div className={`${style.icon} ${style.twoColumn}`} style={{ width: '100%' }}>
                                <div style={{ marginRight: '0.4em' }}>
                                    <IconFit />
                                </div>
                                <span style={{ fontSize: 'smaller', lineHeight: '1.2' }}>FIT</span>
                            </div>
                            <div className={style.domainTitle}>
                                Community{' '}
                                <span className={style.tooltipIcon}>
                                    <IconInfo />
                                </span>
                            </div>
                        </Tooltip>
                    </td>
                    {data[0] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[0]} domain="community" />
                        </td>
                    )}
                    {data[1] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[1]} domain="community" />
                        </td>
                    )}
                    {data[2] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[2]} domain="community" />
                        </td>
                    )}
                </tr>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`}>
                        <Tooltip
                            title="Does the intervention fit with our organization’s leadership priorities, current programming (e.g., training schedule, other activities), or other important characteristics?"
                            placement="right"
                        >
                            <div className={`${style.icon} ${style.twoColumn}`} style={{ width: '100%' }}>
                                <div style={{ marginRight: '0.4em' }}>
                                    <IconFit />
                                </div>
                                <span style={{ fontSize: 'smaller', lineHeight: '1.2' }}>FIT</span>
                            </div>
                            <div className={style.domainTitle}>
                                Organization{' '}
                                <span className={style.tooltipIcon}>
                                    <IconInfo />
                                </span>
                            </div>
                        </Tooltip>
                    </td>
                    {data[0] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[0]} domain="organization" />
                        </td>
                    )}
                    {data[1] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[1]} domain="organization" />
                        </td>
                    )}
                    {data[2] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[2]} domain="organization" />
                        </td>
                    )}
                </tr>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`}>
                        <Tooltip
                            title={
                                <ul>
                                    <li>
                                        Do staff have the needed time available to be trained? To deliver the
                                        intervention? Consider any additional staff that might be needed—for example, to
                                        serve as backup in case of a facilitator’s absence or transfer.
                                    </li>
                                    <li>
                                        Do you have the number of staff recommended for the intervention? Are staff
                                        adequately trained to deliver the intervention? (Consider education and
                                        training, experience or skills, and comfort with the topic.)
                                    </li>
                                </ul>
                            }
                            placement="right"
                        >
                            <div
                                className={`${style.icon} ${style.twoColumn}`}
                                style={{ width: '100%', borderRadius: '0', padding: '0.5em' }}
                            >
                                <div style={{ marginRight: '0.4em' }}>
                                    <IconCap />
                                </div>
                                <span style={{ fontSize: 'smaller', lineHeight: '1.2' }}>
                                    CAPACITY: Do you have sufficient...
                                </span>
                            </div>
                            <div className={style.domainTitle}>
                                {'Staff Availability & Qualifications '}
                                <span className={style.tooltipIcon}>
                                    <IconInfo />
                                </span>
                            </div>
                        </Tooltip>
                    </td>
                    {data[0] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[0]} domain="staff" />
                        </td>
                    )}
                    {data[1] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[1]} domain="staff" />
                        </td>
                    )}
                    {data[2] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[2]} domain="staff" />
                        </td>
                    )}
                </tr>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`}>
                        <Tooltip
                            title="How committed is your organization's leadership (at all levels) to the intervention you are considering?
                            Does leadership support prevention staff?
                            Is there a prevention champion who will help introduce and sustain new interventions?"
                            placement="right"
                        >
                            <div className={`${style.icon} ${style.twoColumn}`} style={{ width: '100%' }}>
                                <div style={{ marginRight: '0.4em' }}>
                                    <IconCap />
                                </div>
                                <span style={{ fontSize: 'smaller', lineHeight: '1.2' }}>CAPACITY</span>
                            </div>
                            <div className={style.domainTitle}>
                                Leadership Commitment{' '}
                                <span className={style.tooltipIcon}>
                                    <IconInfo />
                                </span>
                            </div>
                        </Tooltip>
                    </td>
                    {data[0] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[0]} domain="lead_commitment" />
                        </td>
                    )}
                    {data[1] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[1]} domain="lead_commitment" />
                        </td>
                    )}
                    {data[2] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[2]} domain="lead_commitment" />
                        </td>
                    )}
                </tr>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`}>
                        <Tooltip
                            title="Are there clear channels of communication in place between levels of leadership (e.g., in case orders are needed to ensure participation)?"
                            placement="right"
                        >
                            <div className={`${style.icon} ${style.twoColumn}`} style={{ width: '100%' }}>
                                <div style={{ marginRight: '0.4em' }}>
                                    <IconCap />
                                </div>
                                <span style={{ fontSize: 'smaller', lineHeight: '1.2' }}>CAPACITY</span>
                            </div>
                            <div className={style.domainTitle}>
                                Leadership Communications{' '}
                                <span className={style.tooltipIcon}>
                                    <IconInfo />
                                </span>
                            </div>
                        </Tooltip>
                    </td>
                    {data[0] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[0]} domain="lead_communication" />
                        </td>
                    )}
                    {data[1] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[1]} domain="lead_communication" />
                        </td>
                    )}
                    {data[2] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[2]} domain="lead_communication" />
                        </td>
                    )}
                </tr>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`}>
                        <Tooltip
                            title="Are any specific materials, equipment, or technology needed (e.g., smartphones or computers with internet access)?"
                            placement="right"
                        >
                            <div className={`${style.icon} ${style.twoColumn}`} style={{ width: '100%' }}>
                                <div style={{ marginRight: '0.4em' }}>
                                    <IconCap />
                                </div>
                                <span style={{ fontSize: 'smaller', lineHeight: '1.2' }}>CAPACITY</span>
                            </div>
                            <div className={style.domainTitle}>
                                Technical Equipment{' '}
                                <span className={style.tooltipIcon}>
                                    <IconInfo />
                                </span>
                            </div>
                        </Tooltip>
                    </td>
                    {data[0] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[0]} domain="tech" />
                        </td>
                    )}
                    {data[1] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[1]} domain="tech" />
                        </td>
                    )}
                    {data[2] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[2]} domain="tech" />
                        </td>
                    )}
                </tr>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`}>
                        <Tooltip
                            title="Is there a cost or fee to use the intervention?Are printed materials needed (including curriculum and recruiting flyers)? Are there any other needs (e.g., transportation, space, evaluation materials and efforts)?"
                            placement="right"
                        >
                            <div className={`${style.icon} ${style.twoColumn}`} style={{ width: '100%' }}>
                                <div style={{ marginRight: '0.4em' }}>
                                    <IconCap />
                                </div>
                                <span style={{ fontSize: 'smaller', lineHeight: '1.2' }}>CAPACITY</span>
                            </div>
                            <div className={style.domainTitle}>
                                Financial and Resource Materials{' '}
                                <span className={style.tooltipIcon}>
                                    <IconInfo />
                                </span>
                            </div>
                        </Tooltip>
                    </td>
                    {data[0] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[0]} domain="financial" />
                        </td>
                    )}
                    {data[1] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[1]} domain="financial" />
                        </td>
                    )}
                    {data[2] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[2]} domain="financial" />
                        </td>
                    )}
                </tr>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`}>
                        <Tooltip
                            title="What relationships with other organizations are needed to do this intervention? Are memoranda of understanding or memoranda of agreement for collaboration with nonmilitary entities needed?"
                            placement="right"
                        >
                            <div className={`${style.icon} ${style.twoColumn}`} style={{ width: '100%' }}>
                                <div style={{ marginRight: '0.4em' }}>
                                    <IconCap />
                                </div>
                                <span style={{ fontSize: 'smaller', lineHeight: '1.2' }}>CAPACITY</span>
                            </div>
                            <div className={style.domainTitle}>
                                Collaboration and Partnership{' '}
                                <span className={style.tooltipIcon}>
                                    <IconInfo />
                                </span>
                            </div>
                        </Tooltip>
                    </td>
                    {data[0] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[0]} domain="collab" />
                        </td>
                    )}
                    {data[1] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[1]} domain="collab" />
                        </td>
                    )}
                    {data[2] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[2]} domain="collab" />
                        </td>
                    )}
                </tr>
            </tbody>
        </table>
    );
};

export default table;
