import React from 'react';
import PropTypes from 'prop-types';
import axios from 'cccisd-axios';
import Button from 'cccisd-click-button';
import Notification from 'cccisd-notification';
import Survey from 'js/components/Survey';
import style from './style.css';

const Boilerplate = window.cccisd.boilerplate;

const ProblemCard = props => {
    const archiveProblem = async () => {
        const { deployment, metricspawn } = props;

        const problemData = {
            sourceId: deployment.assignmentId,
            sourceType: 'assignment',
            values: JSON.stringify({ problem_archived: 1 }),
            pawnId: metricspawn.pawnId,
            pawnHash: metricspawn.pawnHash,
            assignmentProgressId: deployment.assignmentProgressId,
            responseSet: deployment.deploymentId,
        };

        const response = await axios.post(Boilerplate.route('metrics.api.v1.data.bulkUpdate'), problemData);

        if (response) {
            if (response.data.status === 'success') {
                const message = 'Problem has been archived.';
                Notification(message);
                await props.assignmentProgressQuery.refetch();
            } else {
                Notification({
                    message: 'Unable to archive the problem. Please contact your system administrator.',
                    type: 'danger',
                });
            }
        }
    };

    const getPriority = () => {
        const priority = props.problem.s1_problem_priority;

        if (priority === 'High') {
            return (
                <span className={`label label-danger ${style.label}`} style={{ backgroundColor: '#dd3731' }}>
                    High Priority
                </span>
            );
        }
        if (priority === 'Medium') {
            return (
                <span
                    className={`label label-warning ${style.label}`}
                    style={{ backgroundColor: '#353863' }}
                    // style={{ backgroundColor: '#353863' }}
                >
                    Medium Priority
                </span>
            );
        }
        if (priority === 'Low') {
            return (
                <span
                    className={`label label-info ${style.label}`}
                    style={{ backgroundColor: '#757575' }}
                    // style={{ backgroundColor: '#1880a0' }}
                >
                    Low Priority
                </span>
            );
        }

        return null;
    };

    return (
        <div className={style.container}>
            <div className={style.topBar}>
                <div style={{ marginRight: 'auto' }}>{getPriority()}</div>
                {!props.orgTeamMemberEditingDisabled && (
                    <Button
                        title="Archive"
                        isConfirm
                        confirmationMessage="Archive this problem?"
                        className="btn btn-default btn-xs"
                        onClick={archiveProblem}
                    />
                )}
            </div>
            <div style={{ marginBottom: '0.5em' }}>
                {props.problem.s1_problem_name ? (
                    <div className={style.problemName}>{props.problem.s1_problem_name}</div>
                ) : (
                    <div style={{ fontStyle: 'italic', color: '#525252' }}>Define a Problem in Step 1</div>
                )}
            </div>
            <Survey
                metricspawn={props.metricspawn}
                deployment={props.deployment}
                step2Alerts={props.step2Alerts}
                assignmentProgressQuery={props.assignmentProgressQuery}
                orgTeamMemberEditingDisabled={props.orgTeamMemberEditingDisabled}
            />
        </div>
    );
};

ProblemCard.propTypes = {
    deployment: PropTypes.object,
    metricspawn: PropTypes.object,
    step2Alerts: PropTypes.object,
    problem: PropTypes.object,
    assignmentProgressQuery: PropTypes.object,
    orgTeamMemberEditingDisabled: PropTypes.bool,
};

export default ProblemCard;
