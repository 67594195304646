import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import style from './style.css';
import Logo from './egto-logo.svg';

const Fortress = window.cccisd.fortress;

const ReportHeader = props => {
    const {
        header: { title },
    } = props.settings;

    return (
        <div className={style.headerWrapper}>
            <div className={style.headerLeft}>
                <img src={Logo} alt="" className={style.logo} />
            </div>
            <div className={style.headerRight}>
                <div className={style.title}>
                    <div className={style.groupName}>{title}</div>
                </div>
                <div>
                    <span className={style.subHeading}>VSO: </span>
                    <span>{Fortress.user.acting.group ? Fortress.user.acting.group.label : 'My VSO'}</span>
                </div>
                <div>
                    <span className={style.subHeading}>Date: </span>
                    {moment(new Date()).format('MMMM Do, YYYY')}
                </div>
            </div>
        </div>
    );
};

ReportHeader.propTypes = {
    settings: PropTypes.object,
    filters: PropTypes.object,
};

ReportHeader.defaultProps = {
    settings: {
        header: {
            title: 'GTO Intervention Comparison Report',
        },
    },
};

export default ReportHeader;
