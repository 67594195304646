import React from 'react';
import { NavLink } from 'cccisd-react-router';
import NavBar from './NavBar';
import logo from './header-logo.png';
import style from './style.css';

const Fortress = window.cccisd.fortress;
const userType = Fortress.user.acting.data_type; // guest, uberadmin, guAdmin, guTeamMember

export const ExternalLink = ({ to, children, className }) => {
    return (
        <li className={className}>
            <a href={to}>{children}</a>
        </li>
    );
};

const Header = () => {
    const pathArray = window.parent.location.pathname.split('/').filter(Boolean);
    const endpoint = pathArray.length > 0 ? pathArray[pathArray.length - 1] : '';

    const getUserNav = () => {
        if (userType === 'guAdmin') {
            return (
                <>
                    <li>
                        <NavLink to="/planning" className={endpoint === 'planning' ? style.active : ''}>
                            e-GTO Planning
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/resourcecenter" className={endpoint === 'resourcecenter' ? style.active : ''}>
                            Resources
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/manage" className={endpoint === 'manage' ? style.active : ''}>
                            Manage
                        </NavLink>
                    </li>
                </>
            );
        }
        if (userType === 'guTeamMember') {
            return (
                <>
                    <li>
                        <NavLink to="/planning" className={endpoint === 'planning' ? style.active : ''}>
                            e-GTO Planning
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/resourcecenter" className={endpoint === 'resourcecenter' ? style.active : ''}>
                            Resources
                        </NavLink>
                    </li>
                </>
            );
        }
        if (userType === 'uberadmin') {
            return (
                <>
                    <li>
                        <NavLink to="/resourcecenter" className={endpoint === 'resourcecenter' ? style.active : ''}>
                            Resources
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/resources/admin" className={endpoint === '/resources/admin' ? style.active : ''}>
                            Resource Admin
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/manage" className={endpoint === 'manage' ? style.active : ''}>
                            Manage
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/quest" className={['data', 'quest'].includes(endpoint) ? style.active : ''}>
                            Data
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/appdefs/logs"
                            className={['logs', 'appdefs'].includes(endpoint) ? style.active : ''}
                        >
                            System Logs
                        </NavLink>
                    </li>
                </>
            );
        }
    };

    return (
        <>
            <NavBar
                className={style.navbar}
                logo={<img src={logo} className={style.headerLogo} alt="e-GTO Logo" />}
                logoLinkTo="https://e-gto.com/"
            >
                <ExternalLink to="https://e-gto.com" className={[''].includes(endpoint) ? style.active : ''}>
                    Home
                </ExternalLink>
                <ExternalLink to="https://e-gto.com/about" className={['about'].includes(endpoint) ? style.active : ''}>
                    About
                </ExternalLink>
                {getUserNav()}
            </NavBar>
        </>
    );
};

export default Header;
