import { useQuery } from 'cccisd-react-query';
import { client } from 'cccisd-apollo';
import metricsPawnQuery from './getMetricsPawn.graphql';
import groupAssignmentProgress from './groupAssignmentProgress.graphql';
import groupUsersQuery from './getGroupUsers.graphql';
import stepsAssignmentQuery from './getStepsAssignment.graphql';
import step2ListVars from './step2ListVars.graphql';
import step3ListVars from './step3ListVars.graphql';
import step4ListVars from './step4ListVars.graphql';
import step5ListVars from './step5ListVars.graphql';
import step6ListVars from './step6ListVars.graphql';

import step2Alerts from './step2Alerts.graphql';
import step3Alerts from './step3Alerts.graphql';
import step4Alerts from './step4Alerts.graphql';
import step5Alerts from './step5Alerts.graphql';

const Fortress = window.cccisd.fortress;
const assignmentHandle = 'gto';

const fetchMetricspawn = async () => {
    const response = await client.query({
        query: metricsPawnQuery,
        fetchPolicy: 'network-only',
    });
    return response;
};

const fetchAssignmentProgress = async metricspawn => {
    const result = await client.query({
        query: groupAssignmentProgress,
        fetchPolicy: 'network-only',
        variables: {
            pawnId: metricspawn,
            assignmenthandle: assignmentHandle,
        },
    });

    const flattenedResult = result.data.roles.metricspawn.assignmentProgressList.map(ap => ({
        deploymentId: ap.deployment.deploymentId,
        timepoint: ap.deployment.timepoint,
        status: ap.status,
        ...ap,
    }));

    return flattenedResult;
};

const fetchGroupUsers = async () => {
    const response = await client.query({
        query: groupUsersQuery,
        variables: {
            groupId: Fortress.user.acting.group?.id,
        },
        fetchPolicy: 'network-only',
    });
    return response;
};

const fetchStepsAssignment = async (metricspawn, deploymentId) => {
    const response = await client.query({
        query: stepsAssignmentQuery,
        variables: {
            deploymentId,
            pawnId: metricspawn,
        },
        fetchPolicy: 'network-only',
    });

    return response;
};

const fetchXListVars = async (metricspawn, deploymentId, query) => {
    const response = await client.query({
        query,
        variables: {
            pawnId: metricspawn,
            deploymentId,
        },
        fetchPolicy: 'network-only',
    });
    return response;
};

const fetchXAlerts = async (metricspawn, deploymentId, query) => {
    const response = await client.query({
        query,
        fetchPolicy: 'network-only',
        variables: {
            pawnId: metricspawn,
            assignmenthandle: assignmentHandle,
            deploymentId,
        },
    });
    return response;
};

/* **************
 *   Query Hooks
 ***************** */

export const useMetricspawnQuery = (key = 'metricspawn', select, notifyOnChangeProps) =>
    useQuery([key], fetchMetricspawn, {
        select: data => data.data.groups.groupingUnit,
        refetchOnWindowFocus: false,
    });

export const useAssignmentProgress = (key = 'assignmentProgress', metricspawn) =>
    useQuery([key], () => fetchAssignmentProgress(metricspawn), {
        enabled: !!metricspawn,
        refetchOnWindowFocus: false,
    });

export const useGetUsersQuery = (key = 'groupUsers', select, notifyOnChangeProps) =>
    useQuery([key], fetchGroupUsers, { refetchOnWindowFocus: false });

export const useGetStepsAssignmentQuery = (key, metricspawn, deploymentId, select, notifyOnChangeProps) =>
    useQuery([key], () => fetchStepsAssignment(metricspawn, deploymentId), {
        enabled: !!metricspawn && !!deploymentId,
        refetchOnWindowFocus: false,
    });

/*
 * Disabled queries are refetched manually in component
 */
export const useStep2ListVarsQuery = (key, metricspawn, deploymentId) =>
    useQuery([key], () => fetchXListVars(metricspawn, deploymentId, step2ListVars), {
        enabled: false,
        refetchOnWindowFocus: false,
    });

export const useStep3ListVarsQuery = (key, metricspawn, deploymentId) =>
    useQuery([key], () => fetchXListVars(metricspawn, deploymentId, step3ListVars), {
        enabled: false,
        refetchOnWindowFocus: false,
    });

export const useStep4ListVarsQuery = (key, metricspawn, deploymentId) =>
    useQuery([key], () => fetchXListVars(metricspawn, deploymentId, step4ListVars), {
        enabled: false,
        refetchOnWindowFocus: false,
    });

export const useStep5ListVarsQuery = (key, metricspawn, deploymentId) =>
    useQuery([key], () => fetchXListVars(metricspawn, deploymentId, step5ListVars), {
        enabled: false,
        refetchOnWindowFocus: false,
    });

export const useStep6ListVarsQuery = (key, metricspawn, deploymentId) =>
    useQuery([key], () => fetchXListVars(metricspawn, deploymentId, step6ListVars), {
        enabled: false,
        refetchOnWindowFocus: false,
    });

export const useStep2AlertsQuery = (key, metricspawn, deploymentId, step2Progress) =>
    useQuery([key], () => fetchXAlerts(metricspawn, deploymentId, step2Alerts), {
        enabled: !!metricspawn && !!deploymentId && !!step2Progress,
        refetchOnWindowFocus: false,
    });

export const useStep3AlertsQuery = (key, metricspawn, deploymentId, step3Progress) =>
    useQuery([key], () => fetchXAlerts(metricspawn, deploymentId, step3Alerts), {
        enabled: !!metricspawn && !!deploymentId && !!step3Progress,
        refetchOnWindowFocus: false,
    });

export const useStep4AlertsQuery = (key, metricspawn, deploymentId, step4Progress) =>
    useQuery([key], () => fetchXAlerts(metricspawn, deploymentId, step4Alerts), {
        enabled: !!metricspawn && !!deploymentId && !!step4Progress,
        refetchOnWindowFocus: false,
    });

export const useStep5AlertsQuery = (key, metricspawn, deploymentId, step5Progress) =>
    useQuery([key], () => fetchXAlerts(metricspawn, deploymentId, step5Alerts), {
        enabled: !!metricspawn && !!deploymentId && !!step5Progress,
        refetchOnWindowFocus: false,
    });
