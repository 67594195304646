import React, { useRef } from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import Modal from 'cccisd-modal';
import Tooltip from 'cccisd-tooltip';
import Button from 'cccisd-click-button';
import IconIncomplete from 'cccisd-icons/pencil7';
import IconComplete from 'cccisd-icons/checkmark';
import IconReview from 'cccisd-icons/search5';
import IconAlert from 'cccisd-icons/warning2';
import IconMitigated from './Resolved.svg';
import style from './style.css';

const ModalButton = props => {
    const modalRef = useRef(null);

    const getIcon = () => {
        const { icon } = props;
        if (icon === 'alert') {
            return (
                <span style={{ color: '#c5002e' }}>
                    <IconAlert />
                </span>
            );
        }
        if (icon === 'complete') {
            return (
                <span style={{ color: '#353863' }}>
                    <IconComplete />
                </span>
            );
        }
        if (icon === 'incomplete') {
            return (
                <span style={{ color: '#353863' }}>
                    <IconIncomplete />
                </span>
            );
        }
        if (icon === 'review') {
            return (
                <span style={{ color: '#353863' }}>
                    <IconReview />
                </span>
            );
        }
        if (icon === 'mitigated') {
            return (
                <span style={{ color: '#353863' }}>
                    <img src={IconMitigated} alt="" style={{ width: '1em' }} />
                </span>
            );
        }
        return '';
    };

    const closeModal = () => {
        modalRef.current.close();
    };

    return (
        <div>
            <Tooltip title={props.tooltip}>
                <Modal
                    ref={modalRef}
                    trigger={
                        <button
                            type="button"
                            className={`${props.className} ${style.modalButton}`}
                            style={props.disabled ? { ...props.style, opacity: '0.8' } : props.style}
                            disabled={props.disabled}
                        >
                            <>
                                {getIcon()} {props.buttonContent}
                            </>
                        </button>
                    }
                    size="100%"
                    beforeShow={props.beforeShow}
                    beforeClose={props.beforeClose}
                    afterClose={props.afterClose}
                    headerStyle={{ display: 'none' }}
                >
                    <div
                        style={{
                            borderRadius: '6px 6px 0 0',
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '1em',
                            paddingBottom: '0.5em',
                            borderBottom: '1px solid #eee',
                        }}
                    >
                        <h4 className="modal-title" style={{ flex: '1' }}>
                            {props.modalTitle}
                        </h4>
                        <Button
                            className="btn btn-primary"
                            onClick={closeModal}
                            isLoading={props.closingModal}
                            title={props.closingModal ? 'Loading...' : 'Return to Planning Page'}
                        />
                    </div>
                    {props.children}
                </Modal>
            </Tooltip>
        </div>
    );
};

ModalButton.propTypes = {
    children: PropTypes.node,
    beforeShow: PropTypes.func,
    beforeClose: PropTypes.func,
    afterClose: PropTypes.func,
    modalTitle: PropTypes.string,
    buttonContent: oneOfType([PropTypes.string, PropTypes.node]),
    size: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    disabled: PropTypes.bool,
    icon: PropTypes.string,
    tooltip: oneOfType([PropTypes.string, PropTypes.node]),
    closingModal: PropTypes.bool,
};

export default ModalButton;
