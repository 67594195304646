import React from 'react';
import style from './style.css';
import NoIcon from 'cccisd-icons/info2';
import YesIcon from 'cccisd-icons/checkmark-circle';

const Changes = props => {
    const renderIcon = () => {
        if (props.data) {
            const answer = props.data[props.domain + '_yn'];
            if (answer === 'No') {
                return (
                    <span style={{ color: '#c5002e' }}>
                        <NoIcon />
                    </span>
                );
            }
            if (answer === 'Yes') {
                return (
                    <span style={{ color: 'green' }}>
                        <YesIcon />
                    </span>
                );
            }
            return '';
        }
        return '';
    };

    const renderContent = () => {
        const domainList = [
            '_need',
            '_availability_need',
            '_qualifications_need',
            '_ad_1',
            '_ad_2',
            '_ad_3',
            '_ad_4',
            '_task_1',
            '_task_2',
            '_task_3',
            '_task_4',
        ].map(suffix => props.data[props.domain + suffix]);

        const noContentMessage = (
            <div style={{ padding: '0.5em', color: '#999' }}>NO CHANGES RECORDED</div>
        );

        if (!props.data) {
            return noContentMessage;
        }

        if (domainList.filter(Boolean).length < 1) {
            return noContentMessage;
        }

        return domainList.map(content => {
            if (content) {
                return <li key={content}>{content}</li>;
            }
            return null;
        });
    };

    return (
        <div className={style.wrapper}>
            <div className={style.youAnswered} style={{ textAlign: 'end' }}>
                {renderIcon()} You Answered{' '}
                <span style={{ fontWeight: 'bold' }}>{props.data[props.domain + '_yn']}</span>
            </div>
            <div className={style.bottomTitle}>CHANGES NEEDED?</div>
            <div className={style.textContent}>
                <ul>{renderContent()}</ul>
            </div>
        </div>
    );
};

export default Changes;
