import React, { useRef } from 'react';
import Tooltip from 'cccisd-tooltip';
import Loader from 'cccisd-loader';
import Modal from 'cccisd-modal';
import LogicModel from 'js/components/LogicModel';
import PlanningBox from 'js/components/PlanningBox';
import ProblemBox from 'js/components/ProblemBox';
import ArchivedProblems from 'js/components/ArchivedProblems';
import style from './style.css';
import LockIcon from 'cccisd-icons/lock';
import IconTable from 'cccisd-icons/table';
import { useMetricspawnQuery, useAssignmentProgress } from 'js/queries';
import { useIsFetching } from 'cccisd-react-query';
import IconArrow from 'cccisd-icons/forward';

const Fortress = window.cccisd.fortress;
const userRole = Fortress.user.acting.data_type;
const orgTeamMemberEditingDisabled = userRole === 'guTeamMember' && Fortress.user.acting.role_data.canEdit === 0;

const DataEntry = () => {
    const isFetching = useIsFetching();
    const modalRef = useRef(null);
    const metricspawnQuery = useMetricspawnQuery();
    const metricspawn = metricspawnQuery?.data?.descendantRoles?.metricspawn?.pawn;
    const assignmentProgressQuery = useAssignmentProgress('assignmentProgress', metricspawn?.pawnId);

    const isLoading = metricspawnQuery.isLoading || assignmentProgressQuery.isLoading;

    const allQueryDataReturned = metricspawnQuery.data && assignmentProgressQuery.data;

    const filterArchivedProblems = data => {
        return data.filter(p => p.archived.problem_archived === '1');
    };

    if (isLoading) {
        return <Loader loading />;
    }

    if (allQueryDataReturned) {
        const thereAreArchivedProblems = filterArchivedProblems(assignmentProgressQuery.data)?.length > 0;
        const assignmentProgress = assignmentProgressQuery.data;
        const thereIsStep3Progress = assignmentProgress.some(
            ap => ap.assignment.surveyList[2].flow?.flowProgress.status
        );

        return (
            <div className="container">
                <div className={style.wrapper}>
                    <div className={`panel panel-default ${style.summaryBox}`}>
                        <div
                            className="panel-heading"
                            style={{
                                color: 'hsl(215 53% 34% / 1)',
                                backgroundColor: 'hsl(212.31deg 52% 95.1%)',
                                fontWeight: 'bold',
                                fontSize: 'smaller',
                                borderColor: '#ecf2f9',
                            }}
                        >
                            INSTRUCTIONS
                        </div>
                        <div
                            className={`panel-body ${style.summaryText}`}
                            style={{
                                display: 'flex',
                            }}
                        >
                            {[...Array(21)].map((x, i) => (
                                <div className={style.chevronArrow} key={i} style={{ left: i * 50 - 30 }} />
                            ))}
                            <div style={{ zIndex: '1000', color: '#353863' }}>
                                <Tooltip
                                    title={
                                        <div>
                                            <h5>Logic models</h5>
                                            <p>
                                                Logic models are diagrams that link a goal to behaviors directly related
                                                to it, factors that influence those behaviors, intervention activities
                                                designed to change those factors, and inputs needed to conduct
                                                intervention activities.
                                            </p>
                                        </div>
                                    }
                                >
                                    <span style={{ textDecoration: 'underline' }}>
                                        The <span style={{ fontWeight: 'bold' }}>Logic Model</span> provides a detailed
                                        work plan for implementing an intervention.
                                    </span>
                                </Tooltip>{' '}
                                To populate the full logic model, complete all six Steps in the{' '}
                                <span style={{ fontWeight: 'bold' }}>
                                    Planning/Pre-
                                    <Tooltip
                                        title={
                                            <div>
                                                <h5>Implementation</h5>
                                                <p>
                                                    The act of putting a plan, program, policies, and practices into
                                                    action.
                                                </p>
                                            </div>
                                        }
                                    >
                                        <span style={{ textDecoration: 'underline' }}>Implementation</span>
                                    </Tooltip>{' '}
                                    Phase
                                </span>
                                . You can view a partially completed logic model after completing Step 3.
                            </div>
                            <div className={style.summaryButtonBox}>
                                <Modal
                                    ref={modalRef}
                                    trigger={
                                        thereIsStep3Progress ? (
                                            <button type="button" className={`btn ${style.purpleButton}`}>
                                                <span />
                                                <IconTable /> Logic Model
                                            </button>
                                        ) : (
                                            <Tooltip title="Complete Step 3 for any problem to view your Logic Model.">
                                                <button
                                                    type="button"
                                                    className={`btn ${style.purpleButton}`}
                                                    style={{ marginRight: '1em' }}
                                                    disabled
                                                >
                                                    <span />
                                                    <IconTable /> Logic Model
                                                </button>
                                            </Tooltip>
                                        )
                                    }
                                    size="100%"
                                    headerStyle={{ display: 'none' }}
                                    title={isFetching > 0 ? <Loader loading /> : 'Logic Model'}
                                >
                                    <div
                                        style={{
                                            borderRadius: '6px 6px 0 0',
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginBottom: '1em',
                                            paddingBottom: '0.5em',
                                            borderBottom: '1px solid #eee',
                                        }}
                                    >
                                        <h4 className="modal-title" style={{ flex: '1' }}>
                                            <div>Logic Model</div>
                                        </h4>

                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() => modalRef.current.close()}
                                        >
                                            Return to Planning Page
                                        </button>
                                    </div>
                                    <LogicModel assignmentProgress={assignmentProgress} metricspawn={metricspawn} />
                                </Modal>
                            </div>
                        </div>
                    </div>
                    <div className={style.mainContent}>
                        <div className={style.leftColumn}>
                            <div className={style.leftColumnContent}>
                                <PlanningBox />
                                <div className={style.arrow}>
                                    <IconArrow />
                                </div>
                            </div>

                            <div className={style.leftColumnContent}>
                                <Tooltip title="This phase is in development">
                                    <div className={style.lockedComponent}>
                                        <LockIcon /> Program Delivery
                                    </div>
                                </Tooltip>
                                <div className={style.arrow}>
                                    <IconArrow />
                                </div>
                            </div>

                            <div className={style.leftColumnContent}>
                                <Tooltip title="This phase is in development">
                                    <div className={style.lockedComponent}>
                                        <LockIcon /> Improvement/Post-Implementation Phase
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                        <div className={style.rightColumn}>
                            <ProblemBox orgTeamMemberEditingDisabled={orgTeamMemberEditingDisabled} />
                            {thereAreArchivedProblems && (
                                <ArchivedProblems orgTeamMemberEditingDisabled={orgTeamMemberEditingDisabled} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default DataEntry;
