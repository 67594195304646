import React from 'react';

/*
 *   Display notification on survey button
 *
 *   Alert types:
 *       icon: "alert","complete","incomplete","review","mitigated"
 *
 *   returns { icon: String, tooltip: String|Node|Array, status: String };
 */
export const getFlowListInfo = ({
    flowList,
    step2AlertsQuery,
    thereIsStep2Progress,
    step3AlertsQuery,
    thereIsStep3Progress,
    step4AlertsQuery,
    thereIsStep4Progress,
    step5AlertsQuery,
    thereIsStep5Progress,
    deploymentData,
}) => {
    const getStatus = (flow, index) => {
        const reviewSteps = deploymentData?.reviewSteps?.review_steps;
        if (reviewSteps && JSON.parse(reviewSteps).includes(index + 1)) {
            return {
                icon: 'review',
                tooltip: <p>This step has been marked for review based on previous steps changing.</p>,
                status: flow.flow.flowProgress.status,
            };
        }

        /*
         ** Step 2 specific alerts
         **     In the survey, Alert visibility is calculated:
         **         * if any goals (G1, G2, G3) have score >= 1, show alert
         **           (No, Not Sure === 1 for achievable/realistic questions)
         **     In this fn, I have not updated to use the exact same expression,
         **     however the result is the same (Check for ANY "No" / "Not Sure")
         */
        if (index === 1 && !step2AlertsQuery.isLoading && step2AlertsQuery.data && thereIsStep2Progress) {
            const step2Alerts = step2AlertsQuery.data?.data.roles.metricspawn.assignmentProgress.step2Alerts;
            const step2AlertsResolve =
                step2AlertsQuery.data?.data.roles.metricspawn.assignmentProgress.step2AlertsResolve;
            const thereAreAlerts = Object.values(step2Alerts).some(x => ['No', 'Not sure'].includes(x));

            const allAlertsResolved = () => {
                let allResolved = [];
                Object.keys(step2Alerts).forEach(key => {
                    if (step2Alerts[key] && step2Alerts[key] !== 'MetricsDevTags') {
                        // console.log(
                        //     's2 alert: ',
                        //     step2Alerts[key],
                        //     'alert resolved: ',
                        //     step2AlertsResolve[key + '_resolve'],
                        //     ['No', 'Not sure'].includes(step2Alerts[key]) &&
                        //         ['Yes', '1'].includes(step2AlertsResolve[key + '_resolve'])
                        // );
                        if (['No', 'Not sure'].includes(step2Alerts[key])) {
                            if (['Yes', '1'].includes(step2AlertsResolve[key + '_resolve'])) {
                                allResolved.push(true);
                            } else {
                                allResolved.push(false);
                            }
                        }
                    }
                });
                return allResolved.length > 0 && allResolved.every(v => v === true);
            };

            if (thereAreAlerts) {
                if (allAlertsResolved()) {
                    return {
                        icon: 'mitigated',
                        tooltip: <p>Alerts related to goals and outcomes have been marked as resolved.</p>,
                        status: flow.flow.flowProgress.status,
                    };
                }
                return {
                    icon: 'alert',
                    tooltip: <p>&#9888; Identified goals may not be achievable or realistic</p>,
                    status: flow.flow.flowProgress.status,
                };
            }
        }

        /*
         ** Step 3 specific alerts
         */
        if (index === 2 && !step3AlertsQuery.isLoading && step3AlertsQuery.data && thereIsStep3Progress) {
            let flowStatus = { icon: '', tooltip: [], status: flow.flow.flowProgress.status };

            const step3Alerts = step3AlertsQuery.data?.data.roles.metricspawn.assignmentProgress.step3Alerts;
            const step3AlertsResolve =
                step3AlertsQuery.data?.data.roles.metricspawn.assignmentProgress.step3AlertsResolve;

            const getStep3AlertStatus = domain => {
                const alerts = ['i1', 'i2', 'i3', 'i4', 'i5', 'i6'].map(
                    i =>
                        step3Alerts[`s3_${i}_proceed`] === '0' &&
                        step3Alerts[`s3_${i}_alert_formula`] >= 2 &&
                        step3Alerts[`s3_${i}_alert_formula`] < 99
                );
                return alerts.some(x => x);
            };

            const alertsResolved = domain => {
                let allResolved = [];
                ['i1', 'i2', 'i3', 'i4', 'i5', 'i6'].forEach(key => {
                    if (
                        step3Alerts[`step3_${key}_${domain}_yn`] &&
                        step3Alerts[`step3_${key}_${domain}_yn`] !== 'MetricsDevTags'
                    ) {
                        if (
                            ['No', 'Not sure', '1', '0.5'].includes(step3Alerts[`step3_${key}_${domain}_yn`]) &&
                            ['Yes', '1'].includes(step3AlertsResolve[`s3_${key}_${domain}_resolve`])
                        ) {
                            allResolved.push(true);
                        } else {
                            allResolved.push(false);
                        }
                    }
                });
                return allResolved.length > 0 && allResolved.every(v => v === true);
            };

            const thereIsEffectivenessAlert = getStep3AlertStatus('eoe');
            const thereIsFocusAlert = getStep3AlertStatus('focus');
            const thereIsImplementaionAlert = getStep3AlertStatus('implementation');
            const thereIsTeachingAlert = getStep3AlertStatus('teaching');
            const thereIsTargetAlert = getStep3AlertStatus('target');

            const allEffectivenessAlertsResolved = alertsResolved('eoe');
            const allFocusAlertsResolved = alertsResolved('focus');
            const allImplementaionAlertsResolved = alertsResolved('implementation');
            const allTeachingAlertsResolved = alertsResolved('teaching');
            const allTargetAlertsResolved = alertsResolved('target');

            const alertsHaveBeenResolved =
                allEffectivenessAlertsResolved ||
                allFocusAlertsResolved ||
                allImplementaionAlertsResolved ||
                allTeachingAlertsResolved ||
                allTargetAlertsResolved;

            if (thereIsEffectivenessAlert && !allEffectivenessAlertsResolved) {
                flowStatus.icon = 'alert';
                flowStatus.tooltip.push(
                    <p key="eoe">
                        &#9888; Intervention may not have evidence of effectiveness with your target population.
                    </p>
                );
            }
            if (thereIsFocusAlert && !allFocusAlertsResolved) {
                flowStatus.icon = 'alert';
                flowStatus.tooltip.push(
                    <p key="focus">&#9888; Intervention may not align with your desired goals and outcomes.</p>
                );
            }
            if (thereIsImplementaionAlert && !allImplementaionAlertsResolved) {
                flowStatus.icon = 'alert';
                flowStatus.tooltip.push(
                    <p key="implementation">
                        Intervention may not provide necessary activities and materials to support implementation.
                    </p>
                );
            }
            if (thereIsTeachingAlert && !allTeachingAlertsResolved) {
                flowStatus.icon = 'alert';
                flowStatus.tooltip.push(
                    <p>&#9888; Intervention may not employ teaching methods to actively involve participants.</p>
                );
            }
            if (thereIsTargetAlert && !allTargetAlertsResolved) {
                flowStatus.icon = 'alert';
                flowStatus.tooltip.push(
                    <p key="target">
                        &#9888; Intervention may not employ activities, instructional methods, and behavioral messages
                        appropriate for your target population.
                    </p>
                );
            }

            if (flowStatus.tooltip.length > 0) {
                if (flowStatus.tooltip.length > 2) {
                    return {
                        icon: flowStatus.icon,
                        tooltip: shortenTooltipText(flowStatus.tooltip),
                        status: flow.flow.flowProgress.status,
                    };
                }
                return flowStatus;
            }

            if (flowStatus.tooltip.length < 1 && alertsHaveBeenResolved) {
                return {
                    icon: 'mitigated',
                    tooltip: [<p>Alerts related to interventions have been marked as resolved.</p>],
                    status: flow.flow.flowProgress.status,
                };
            }
        }

        /*
         ** Step 4 alerts
         */
        if (index === 3 && !step4AlertsQuery.isLoading && step4AlertsQuery.data && thereIsStep4Progress) {
            let flowStatus = { icon: '', tooltip: [], status: flow.flow.flowProgress.status };

            const step4Alerts = step4AlertsQuery.data?.data.roles.metricspawn.assignmentProgress.step4Alerts;
            const step4AlertsResolve =
                step4AlertsQuery.data?.data.roles.metricspawn.assignmentProgress.step4AlertsResolve;

            const getStep4AlertStatus = domain => {
                const alerts = ['i1', 'i2', 'i3', 'i4', 'i5', 'i6'].map(i => {
                    // console.log(
                    //     i,
                    //     step4Alerts[`s4_${i}_alert_formula`],
                    //     domain,
                    //     step4Alerts[`s4_${i}_${domain}_yn`]
                    // );
                    return (
                        step4Alerts[`s3_${i}_proceed`] === '0' &&
                        step4Alerts[`s4_${i}_alert_formula`] >= 3 &&
                        step4Alerts[`s4_${i}_alert_formula`] < 99
                    );
                });
                return alerts.some(x => x);
            };

            const alertsResolved = domain => {
                let allResolved = [];
                ['i1', 'i2', 'i3', 'i4', 'i5', 'i6'].forEach(key => {
                    if (
                        step4Alerts[`s4_${key}_${domain}_yn`] &&
                        step4Alerts[`s4_${key}_${domain}_yn`] !== 'MetricsDevTags'
                    ) {
                        if (
                            ['No', '1', '2'].includes(step4Alerts[`s4_${key}_${domain}_yn`]) &&
                            ['Yes', '1'].includes(step4AlertsResolve[`s4_${key}_${domain}_resolve`])
                        ) {
                            allResolved.push(true);
                        } else {
                            allResolved.push(false);
                        }
                    }
                });
                return allResolved.length > 0 && allResolved.every(v => v === true);
            };

            const thereIsCommunityAlert = getStep4AlertStatus('fit_community');
            const thereIsOrgAlert = getStep4AlertStatus('fit_organization');
            const thereIsTargetAlert = getStep4AlertStatus('fit_target');

            const allCommunityAlertsResolved = alertsResolved('fit_community');
            const allOrgAlertsResolved = alertsResolved('fit_organization');
            const allTargetAlertsResolved = alertsResolved('fit_target');

            const alertsHaveBeenResolved =
                allCommunityAlertsResolved || allOrgAlertsResolved || allTargetAlertsResolved;

            if (thereIsCommunityAlert && !allCommunityAlertsResolved) {
                flowStatus.icon = 'alert';
                flowStatus.tooltip.push(
                    <p key="community">
                        &#9888; Intervention may not fit the community cultural norms or adaptations are needed for a
                        better fit.
                    </p>
                );
            }
            if (thereIsOrgAlert && !allOrgAlertsResolved) {
                flowStatus.icon = 'alert';
                flowStatus.tooltip.push(
                    <p key="organization">
                        &#9888; Intervention may not fit your organization’s leadership priorities or adaptations are
                        needed for a better fit.
                    </p>
                );
            }
            if (thereIsTargetAlert && !allTargetAlertsResolved) {
                flowStatus.icon = 'alert';
                flowStatus.tooltip.push(
                    <p key="fit_target">
                        &#9888; Intervention may not fit your target population or adaptations are needed for a better
                        fit.
                    </p>
                );
            }

            if (flowStatus.tooltip.length > 0) {
                if (flowStatus.tooltip.length > 2) {
                    return {
                        icon: flowStatus.icon,
                        tooltip: shortenTooltipText(flowStatus.tooltip),
                        status: flow.flow.flowProgress.status,
                    };
                }
                return flowStatus;
            }
            if (flowStatus.tooltip.length < 1 && alertsHaveBeenResolved) {
                return {
                    icon: 'mitigated',
                    tooltip: [<p>Alerts related to fit have been marked as resolved.</p>],
                    status: flow.flow.flowProgress.status,
                };
            }
        }

        /*
         ** Step 5 specific alerts
         */
        if (index === 4 && !step5AlertsQuery.isLoading && step5AlertsQuery.data && thereIsStep5Progress) {
            let flowStatus = { icon: '', tooltip: [], status: flow.flow.flowProgress.status };

            const step5Alerts = step5AlertsQuery.data?.data.roles.metricspawn.assignmentProgress.step5Alerts;
            const step5AlertsResolve =
                step5AlertsQuery.data?.data.roles.metricspawn.assignmentProgress.step5AlertsResolve;

            const getStep5AlertStatus = domain => {
                const alerts = ['i1', 'i2', 'i3', 'i4', 'i5', 'i6'].map(i => {
                    return (
                        step5Alerts[`s3_${i}_proceed`] === '0' &&
                        step5Alerts[`s5_${i}_alert_formula`] >= 3 &&
                        step5Alerts[`s5_${i}_alert_formula`] < 99
                    );
                });
                return alerts.some(x => x);
            };

            const alertsResolved = domain => {
                let allResolved = [];
                ['i1', 'i2', 'i3', 'i4', 'i5', 'i6'].forEach(key => {
                    if (
                        step5Alerts[`s5_${key}_${domain}_yn`] &&
                        step5Alerts[`s5_${key}_${domain}_yn`] !== 'MetricsDevTags'
                    ) {
                        if (
                            ['No', '1', '0.5'].includes(step5Alerts[`s5_${key}_${domain}_yn`]) &&
                            ['Yes', '1'].includes(step5AlertsResolve[`s5_${key}_${domain}_resolve`])
                        ) {
                            allResolved.push(true);
                        } else {
                            allResolved.push(false);
                        }
                    }
                });
                return allResolved.length > 0 && allResolved.every(v => v === true);
            };

            const thereIsStaffAlert = getStep5AlertStatus('cap_staff');
            const thereIsCommitmentAlert = getStep5AlertStatus('cap_lead_commitment');
            const thereIsCommunicationAlert = getStep5AlertStatus('cap_lead_communication');
            const thereIsTechAlert = getStep5AlertStatus('cap_tech');
            const thereIsFinancialAlert = getStep5AlertStatus('cap_financial');
            const thereIsCollabAlert = getStep5AlertStatus('cap_collab');

            const allStaffAlertsResolved = alertsResolved('cap_staff');
            const allCommitmentAlertsResolved = alertsResolved('cap_lead_commitment');
            const allCommunicationAlertsResolved = alertsResolved('cap_lead_communication');
            const allTechAlertsResolved = alertsResolved('cap_tech');
            const allFinancialAlertsResolved = alertsResolved('cap_financial');
            const allCollabAlertsResolved = alertsResolved('cap_collab');

            const alertsHaveBeenResolved =
                allStaffAlertsResolved ||
                allCommitmentAlertsResolved ||
                allCommunicationAlertsResolved ||
                allTechAlertsResolved ||
                allFinancialAlertsResolved ||
                allCollabAlertsResolved;

            if (thereIsStaffAlert && !allStaffAlertsResolved) {
                flowStatus.icon = 'alert';
                flowStatus.tooltip.push(
                    <p key="staff">
                        &#9888; Staff availability & qualifications may not be adequate to support the intervention.
                        Please ensure you have identified needed hiring & training tasks to support successful
                        implementation in this step.
                    </p>
                );
            }
            if (thereIsCommitmentAlert && !allCommitmentAlertsResolved) {
                flowStatus.icon = 'alert';
                flowStatus.tooltip.push(
                    <p key="lead_commitment">
                        &#9888; Leadership commitment may not be adequate to support the intervention. Please ensure you
                        have identified needed tasks to support leadership commitment in this step.
                    </p>
                );
            }
            if (thereIsCommunicationAlert && !allCommunicationAlertsResolved) {
                flowStatus.icon = 'alert';
                flowStatus.tooltip.push(
                    <p key="lead_communication">
                        &#9888; Leadership capacity related to communication may not be adequate to support the
                        intervention. Please ensure you have identified needed tasks to support leadership communication
                        capacity in this step.
                    </p>
                );
            }
            if (thereIsTechAlert && !allTechAlertsResolved) {
                flowStatus.icon = 'alert';
                flowStatus.tooltip.push(
                    <p key="tech">
                        &#9888; Technical capacity within your organization may not be adequate to support the
                        intervention. Please ensure that you have identified needed tasks to support technical capacity
                        in this step.
                    </p>
                );
            }
            if (thereIsFinancialAlert && !allFinancialAlertsResolved) {
                flowStatus.icon = 'alert';
                flowStatus.tooltip.push(
                    <p key="financial">
                        &#9888; Financial and resource capacity within your organization may not be adequate to support
                        the intervention. Please ensure that you have identified needed tasks to support financial and
                        resource capacity in this step.
                    </p>
                );
            }
            if (thereIsCollabAlert && !allCollabAlertsResolved) {
                flowStatus.icon = 'alert';
                flowStatus.tooltip.push(
                    <p key="collab">
                        &#9888; Collaboration and partnership capacity within your organization may not be adequate to
                        support the intervention. Please ensure that you have identified needed tasks to support
                        collaboration and partnership capacities in this step.
                    </p>
                );
            }

            if (flowStatus.tooltip.length > 0) {
                if (flowStatus.tooltip.length > 2) {
                    return {
                        icon: flowStatus.icon,
                        tooltip: shortenTooltipText(flowStatus.tooltip),
                        status: flow.flow.flowProgress.status,
                    };
                }
                return flowStatus;
            }
            if (flowStatus.tooltip.length < 1 && alertsHaveBeenResolved) {
                return {
                    icon: 'mitigated',
                    tooltip: [<p>Alerts related to capacity have been marked as resolved.</p>],
                    status: flow.flow.flowProgress.status,
                };
            }
        }

        if (flow.flow.flowProgress.status === 'In Progress') {
            return {
                icon: 'incomplete',
                tooltip: 'This step is incomplete',
                status: flow.flow.flowProgress.status,
            };
        }
        if (flow.flow.flowProgress.status === 'Complete') {
            return {
                icon: 'complete',
                tooltip: 'This step is complete',
                status: flow.flow.flowProgress.status,
            };
        }

        return {
            icon: '',
            tooltip: 'This step has not been started',
            status: flow.flow.flowProgress.status,
        };
    };

    let amendedFlowList = flowList;

    amendedFlowList = flowList.map((flow, i) => {
        const status = getStatus(flow, i);
        return {
            ...flow,
            icon: status.icon,
            status: status.status,
            tooltip: status.tooltip,
        };
    });
    return amendedFlowList;
};

/*
 *   If tooltip array > 2, append with '...'
 */
const shortenTooltipText = tooltips => {
    const additionalAlerts = tooltips.length - 2;
    const newTooltips = tooltips.slice(0, 2);
    newTooltips.push(
        <p style={{ fontStyle: 'italic' }} key="...and">
            ...and <span style={{ fontWeight: 'bold' }}>{additionalAlerts}</span> more{' '}
            {additionalAlerts.length < 2 ? 'alert' : 'alerts'}. Open the survey to learn more.
        </p>
    );
    return newTooltips;
};

/*
 *   Domain titles to pass to Step 6 defaultValueVariables
 *   along with the associated task or adaptation
 *      Used in SurveyPlayer.getVarsList()
 */
export const domainKeyTitles = {
    target: 'Target Population',
    community: 'Community',
    organization: 'Organization',
    lead: { commitment: 'Leadership Commitment', communication: 'Leadership Communication' },
    staff: { task: 'Staff' },
    tech: { task: 'Technical Equipment' },
    financial: { task: 'Financial/Resource' },
    collab: { task: 'Collaboration' },
};
