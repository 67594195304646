import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getStep3ReportData } from 'js/reducers/egto.js';
import Loader from 'cccisd-loader';
import Player from './player.js';
import { useMetricspawnQuery } from 'js/queries';

const Component = props => {
    const reduxStore = useSelector(state => state.app.egto);
    const dispatch = useDispatch();
    const metricspawnQuery = useMetricspawnQuery();
    const metricspawn = metricspawnQuery?.data?.descendantRoles?.metricspawn?.pawn;

    useEffect(() => {
        if (metricspawnQuery.status === 'success') {
            (async () => {
                const findDeploymentId = () => {
                    if (props.deploymentId) {
                        return props.deploymentId;
                    }
                    if (reduxStore.currentDeployment) {
                        return reduxStore.currentDeployment.deploymentId;
                    }
                    return 3;
                };
                await dispatch(getStep3ReportData(findDeploymentId(), metricspawn));
            })();
        }
    }, [metricspawnQuery.status]);

    if (!reduxStore.step3ReportDataLoaded || metricspawnQuery.status === 'loading') {
        return <Loader loading />;
    }

    if (reduxStore.step3InterventionList[0].value !== '') {
        return (
            <Player
                {...props}
                interventionList={reduxStore.step3InterventionList}
                reportDataLoaded={reduxStore.step3ReportDataLoaded}
            />
        );
    }
    return null;
};

export default Component;
