import React from 'react';
import style from './style.css';

const Changes = props => {
    const getChangesNeededContent = () => {
        const noChangesMessage = (
            <div style={{ padding: '0.5em', color: '#999' }}>NO CHANGES RECORDED</div>
        );
        if (!props.data) {
            return noChangesMessage;
        }
        if (props.data[props.domain + '_changes'] === '') {
            return noChangesMessage;
        }
        return props.data[props.domain + '_changes'];
    };

    return (
        <div className={style.wrapper}>
            <div className={style.bottomTitle}>CHANGES NEEDED?</div>
            <div className={style.textContent}>{getChangesNeededContent()}</div>
        </div>
    );
};

export default Changes;
