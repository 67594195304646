import React from 'react';
import Changes from './Cell/changes.js';
import Tooltip from 'cccisd-tooltip';
import style from './style.css';
import IconInfo from 'cccisd-icons/info2';
import IconFit from 'cccisd-icons/rulers';

const table = props => {
    const getInterventionData = () => {
        let data = [];
        const thereIsReportData = Object.keys(props.data).length > 0;
        const thereAreValidFilters = typeof props.filters.intervention === 'object';

        if (thereIsReportData) {
            if (thereAreValidFilters) {
                props.filters.intervention.forEach(i => {
                    data.push({ ...props.data[i.value], name: i.label });
                });
            }
            return data;
        }
        return data;
    };

    const data = getInterventionData();

    if (data.length < 1) {
        return (
            <div className="alert alert-info" role="alert">
                <IconInfo /> &nbsp;&nbsp; Select up to three interventions to see a comparison report.
            </div>
        );
    }

    return (
        <table className={style.tg} style={{ width: '100%' }}>
            <colgroup>
                <col style={{ width: '14%' }} />
                <col style={{ width: `${86 / data.length}%` }} />
                <col style={{ width: `${86 / data.length}%` }} />
                <col style={{ width: `${86 / data.length}%` }} />
            </colgroup>
            <thead>
                <tr>
                    <th className={style.tgHeading} />
                    {data[0] && <th className={style.tgHeading}>{data[0] && data[0].name}</th>}
                    {data[1] && <th className={style.tgHeading}>{data[1] && data[1].name}</th>}
                    {data[2] && <th className={style.tgHeading}>{data[2] && data[2].name}</th>}
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`}>
                        <Tooltip
                            title="Does the intervention fit with the target population's needs, demographics (e.g., gender, pay grade, race/ethnicity), or other important characteristics (e.g., education level, work schedule)?"
                            placement="right"
                        >
                            <div
                                className={`${style.icon} ${style.twoColumn}`}
                                style={{ width: '100%', borderRadius: '0', padding: '0.5em' }}
                            >
                                <div style={{ marginRight: '0.4em' }}>
                                    <IconFit />
                                </div>
                                <span style={{ fontSize: 'smaller', lineHeight: '1.2' }}>
                                    Is this a good FIT for your...
                                </span>
                            </div>
                            <div className={style.domainTitle}>
                                Target Population{' '}
                                <span className={style.tooltipIcon}>
                                    <IconInfo />
                                </span>
                            </div>
                        </Tooltip>
                    </td>
                    {data[0] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[0]} domain="target" />
                        </td>
                    )}
                    {data[1] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[1]} domain="target" />
                        </td>
                    )}
                    {data[2] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[2]} domain="target" />
                        </td>
                    )}
                </tr>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`}>
                        <Tooltip
                            title="Does the intervention fit with the community’s cultural norms, values, or other important characteristics (e.g., rural or urban, U.S. or international)?"
                            placement="right"
                        >
                            <div className={style.domainTitle}>
                                Community{' '}
                                <span className={style.tooltipIcon}>
                                    <IconInfo />
                                </span>
                            </div>
                        </Tooltip>
                    </td>
                    {data[0] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[0]} domain="community" />
                        </td>
                    )}
                    {data[1] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[1]} domain="community" />
                        </td>
                    )}
                    {data[2] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[2]} domain="community" />
                        </td>
                    )}
                </tr>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`}>
                        <Tooltip
                            title="Does the intervention fit with our organization’s leadership priorities, current programming (e.g., training schedule, other activities), or other important characteristics?"
                            placement="right"
                        >
                            <div className={style.domainTitle}>
                                Organization{' '}
                                <span className={style.tooltipIcon}>
                                    <IconInfo />
                                </span>
                            </div>
                        </Tooltip>
                    </td>
                    {data[0] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[0]} domain="organization" />
                        </td>
                    )}
                    {data[1] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[1]} domain="organization" />
                        </td>
                    )}
                    {data[2] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[2]} domain="organization" />
                        </td>
                    )}
                </tr>
            </tbody>
        </table>
    );
};

export default table;
