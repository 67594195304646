import React from 'react';
import style from './style.css';
import StepBox from './StepBox';
import stepBoxes from './stepBoxes.js';

const PlanningBox = () => {
    return (
        <div className={style.planningBox}>
            <div className={style.planningTitle}>Planning/Pre-Implementation Phase</div>
            <div className={style.planningSteps}>
                {stepBoxes.map(s => {
                    return (
                        <StepBox
                            key={s.color}
                            title={s.title}
                            content={s.content}
                            color={s.color}
                            tooltip={s.tooltip}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default PlanningBox;
