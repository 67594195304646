import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Loader from 'cccisd-loader';
import notification from 'cccisd-notification';
import Tooltip from 'cccisd-tooltip';
import axios from 'cccisd-axios';
import Button from 'cccisd-click-button';
import {
    useGetUsersQuery,
    useGetStepsAssignmentQuery,
    useStep2ListVarsQuery,
    useStep3ListVarsQuery,
    useStep4ListVarsQuery,
    useStep5ListVarsQuery,
    useStep2AlertsQuery,
    useStep3AlertsQuery,
    useStep4AlertsQuery,
    useStep5AlertsQuery,
} from 'js/queries';
import { setCurrentDeployment } from 'js/reducers/egto.js';
import ModalButton from 'js/components/ModalButton';
import SurveyPlayer from './SurveyPlayer.js';
import Step3Report from 'js/vendor/reports/reportTemplates/Step3Comparison';
import Step4Report from 'js/vendor/reports/reportTemplates/Step4Comparison';
import Step5Report from 'js/vendor/reports/reportTemplates/Step5Comparison';
import { AnswerSummary } from 'cccisd-laravel-appdefs/dist/reportTemplates';
import { getFlowListInfo } from './helpers.js';
import style from './style.css';
import _range from 'lodash/range';

const appDefs = window.cccisd.appDefs;
const Boilerplate = window.cccisd.boilerplate;

const Survey = props => {
    const [loading, setLoading] = useState(true);
    const [showReportOrSurvey, setShowReportOrSurvey] = useState('survey');
    const [closingModal, setClosingModal] = useState(false);
    const dispatch = useDispatch();

    /*
     *   Queries
     */
    const getUsersQuery = useGetUsersQuery();
    const stepsAssignment = useGetStepsAssignmentQuery(
        `stepsAssignment ${props.deployment.deploymentId}`,
        props.metricspawn.pawnId,
        props.deployment.deploymentId
    );

    const thereIsStep2Progress =
        stepsAssignment?.data?.data?.flows.deployment.assignment.surveyList[1].flow.flowProgress.status;
    const thereIsStep3Progress =
        stepsAssignment?.data?.data?.flows.deployment.assignment.surveyList[2].flow.flowProgress.status;
    const thereIsStep4Progress =
        stepsAssignment?.data?.data?.flows.deployment.assignment.surveyList[3].flow.flowProgress.status;
    const thereIsStep5Progress =
        stepsAssignment?.data?.data?.flows.deployment.assignment.surveyList[4].flow.flowProgress.status;
    const completedFlowSteps = stepsAssignment?.data?.data?.flows.deployment.assignment.surveyList.filter(
        x => x.flow.flowProgress.status === 'Complete'
    );

    const step2ListVarsQuery = useStep2ListVarsQuery(
        `step2ListVars ${props.deployment.deploymentId}`,
        props.metricspawn.pawnId,
        props.deployment.deploymentId
    );
    const step3ListVarsQuery = useStep3ListVarsQuery(
        `step3ListVars ${props.deployment.deploymentId}`,
        props.metricspawn.pawnId,
        props.deployment.deploymentId
    );
    const step4ListVarsQuery = useStep4ListVarsQuery(
        `step4ListVars ${props.deployment.deploymentId}`,
        props.metricspawn.pawnId,
        props.deployment.deploymentId
    );
    const step5ListVarsQuery = useStep5ListVarsQuery(
        `step5ListVars ${props.deployment.deploymentId}`,
        props.metricspawn.pawnId,
        props.deployment.deploymentId
    );
    const step2AlertsQuery = useStep2AlertsQuery(
        `step2Alerts ${props.deployment.deploymentId}`,
        props.metricspawn.pawnId,
        props.deployment.deploymentId,
        thereIsStep2Progress
    );
    const step3AlertsQuery = useStep3AlertsQuery(
        `step3Alerts ${props.deployment.deploymentId}`,
        props.metricspawn.pawnId,
        props.deployment.deploymentId,
        thereIsStep3Progress
    );
    const step4AlertsQuery = useStep4AlertsQuery(
        `step4Alerts ${props.deployment.deploymentId}`,
        props.metricspawn.pawnId,
        props.deployment.deploymentId,
        thereIsStep4Progress
    );
    const step5AlertsQuery = useStep5AlertsQuery(
        `step5Alerts ${props.deployment.deploymentId}`,
        props.metricspawn.pawnId,
        props.deployment.deploymentId,
        thereIsStep5Progress
    );

    useEffect(() => {
        setLoading(true);
        (async () => {
            await updateReviewSteps(getReviewSteps());
            setLoading(false);
        })();
    }, [stepsAssignment.data]);

    const isLoading = getUsersQuery.isLoading || stepsAssignment.isLoading;
    const allQueryDataReturned = getUsersQuery.data && stepsAssignment.data;

    const beforeShowSurvey = async (survey, flowListIndex) => {
        setLoading(true);
        if (survey.status === 'Complete') {
            setShowReportOrSurvey('report');
        }
        dispatch(setCurrentDeployment(props.deployment));
        // Only fetch list vars when appropriate survey is opened;
        if (flowListIndex === 2) {
            await step2ListVarsQuery.refetch();
        }
        if ([3, 4, 5].includes(flowListIndex)) {
            await step3ListVarsQuery.refetch();
        }
        if (flowListIndex === 5) {
            await step4ListVarsQuery.refetch();
            await step5ListVarsQuery.refetch();
        }
        setLoading(false);
    };

    const onCloseSurvey = async thisSurvey => {
        setLoading(true);
        setClosingModal(true);
        await props.assignmentProgressQuery.refetch();
        await stepsAssignment.refetch();
        await step2AlertsQuery.refetch();
        await step3AlertsQuery.refetch();
        await step4AlertsQuery.refetch();
        await step5AlertsQuery.refetch();
        await updateReviewSteps(getReviewSteps(thisSurvey));
        setLoading(false);
    };

    const getButtonStyle = flowIndex => {
        const colors = ['#6a6eaf', '#353863', '#c6002e', '#7e141d', '#1e818a', '#176168'];
        return { borderLeft: `8px solid ${colors[flowIndex]}`, width: '100px' };
    };

    const getReportSettings = (reportHandle, reportId) => {
        const reportList = appDefs.reports.list;
        // Generic Report
        if (reportHandle === 'answerSummary') {
            const anyReportWithId = reportList.find(r => r.id === reportId);
            return anyReportWithId.reportTemplateSettings;
        }

        const anyReportWithHandle = reportList.find(r => r.reportTemplateHandle === reportHandle);
        if (anyReportWithHandle) {
            return anyReportWithHandle.reportTemplateSettings;
        }
    };

    const renderIfSurveyComplete = (survey, index) => {
        // Names of reports TODO: might need changing
        const reportIds = {
            0: 'PriorityProblemSummary',
            1: 'GoalsSummary',
            2: 'Step3',
            3: 'Intervention1FitSummary',
            4: 'Intervention1CapacitySummary',
            5: 'Step6Report',
        };
        if (!loading) {
            /*
             *   survey.status is still "incomplete" at this point, although the flow is complete.
             *   But we can show the report here anyways because showSurveyOrReport state is changed onFlowComplete
             */

            if (showReportOrSurvey === 'report') {
                if (index === 2) {
                    return (
                        <Step3Report.player
                            settings={getReportSettings('step3')}
                            deploymentId={props.deployment.deploymentId}
                            metricspawn={props.metricspawn}
                        />
                    );
                }
                if (index === 3) {
                    return (
                        <Step4Report.player
                            settings={getReportSettings('step4')}
                            deploymentId={props.deployment.deploymentId}
                            metricspawn={props.metricspawn}
                        />
                    );
                }
                if (index === 4) {
                    return (
                        <Step5Report.player
                            settings={getReportSettings('step5')}
                            deploymentId={props.deployment.deploymentId}
                            metricspawn={props.metricspawn}
                        />
                    );
                }

                return (
                    <div className={style.centerReport}>
                        <AnswerSummary.player
                            settings={getReportSettings('answerSummary', reportIds[index])}
                            deploymentId={props.deployment.deploymentId}
                            pawnId={props.metricspawn.pawnId}
                        />
                    </div>
                );
            }
            if (!props.orgTeamMemberEditingDisabled) {
                return (
                    <>
                        <SurveyPlayer
                            deployment={props.deployment}
                            surveyList={[survey.surveyHandle]}
                            metricspawn={props.metricspawn}
                            getUsersQuery={getUsersQuery}
                            flowListIndex={index}
                            step2ListVarsQuery={step2ListVarsQuery}
                            step3ListVarsQuery={step3ListVarsQuery}
                            step4ListVarsQuery={step4ListVarsQuery}
                            step5ListVarsQuery={step5ListVarsQuery}
                            setShowReportOrSurvey={setShowReportOrSurvey}
                        />
                    </>
                );
            }
        }
    };

    const updateReviewSteps = async stepsToReview => {
        const { deployment, metricspawn } = props;

        if (stepsToReview) {
            const problemData = {
                sourceId: deployment.assignmentId,
                sourceType: 'assignment',
                values: JSON.stringify({ review_steps: stepsToReview }),
                pawnId: metricspawn.pawnId,
                pawnHash: metricspawn.pawnHash,
                assignmentProgressId: deployment.assignmentProgressId,
                responseSet: deployment.deploymentId,
            };

            const response = await axios.post(Boilerplate.route('metrics.api.v1.data.bulkUpdate'), problemData);

            if (response) {
                if (response.data.status === 'success') {
                    await props.assignmentProgressQuery.refetch();
                }
            }
        }
    };

    const updateHasReviewed = async hasReviewed => {
        const { deployment, metricspawn } = props;

        const problemData = {
            sourceId: deployment.assignmentId,
            sourceType: 'assignment',
            values: JSON.stringify({ has_reviewed: hasReviewed }),
            pawnId: metricspawn.pawnId,
            pawnHash: metricspawn.pawnHash,
            assignmentProgressId: deployment.assignmentProgressId,
            responseSet: deployment.deploymentId,
        };

        const response = await axios.post(Boilerplate.route('metrics.api.v1.data.bulkUpdate'), problemData);

        if (response) {
            if (response.data.status === 'success') {
                await props.assignmentProgressQuery.refetch();
            }
        }
    };

    /*  Get all completed surveyHandles, compare with mostRecentFlow
     *  return Array of ids between mostRecentFlow and completedFlowSteps.length
     *  If 'has_reviewed' metric is set, return nothing.
     */
    const getReviewSteps = thisSurvey => {
        let stepsToReview = [];

        if (completedFlowSteps && props.deployment.hasReviewed.has_reviewed !== '1') {
            const mostRecentFlow = thisSurvey ? thisSurvey.surveyHandle : props.deployment.mostRecentFlow;
            const indexOfMostRecentFlow = completedFlowSteps.map(flow => flow.surveyHandle).indexOf(mostRecentFlow);
            if (indexOfMostRecentFlow !== -1) {
                stepsToReview = _range(indexOfMostRecentFlow + 2, completedFlowSteps.length + 1);
            }

            return stepsToReview;
        }
    };

    const getButtonDisabled = (flowListInfo, survey) => {
        const survey1Status = flowListInfo.find(s => s.surveyHandle === 'survey1').status;

        if (props.orgTeamMemberEditingDisabled && survey.status !== 'Complete') {
            return true;
        }

        if (survey.surveyHandle !== 'survey1' && survey1Status !== 'Complete') {
            return true;
        }
    };

    if (isLoading) {
        return <Loader loading={isLoading} />;
    }

    if (allQueryDataReturned) {
        const flows = stepsAssignment.data.data?.flows.deployment.assignment.surveyList;
        const flowListInfo = getFlowListInfo({
            flowList: flows,
            step2AlertsQuery,
            thereIsStep2Progress,
            step3AlertsQuery,
            thereIsStep3Progress,
            step4AlertsQuery,
            thereIsStep4Progress,
            step5AlertsQuery,
            thereIsStep5Progress,
            deploymentData: props.deployment,
        });
        const reviewSteps = JSON.parse(props.deployment.reviewSteps?.review_steps);

        return (
            <div className={style.stepButtonContainer}>
                {[...Array(47)].map((x, i) => (
                    <div className={style.chevronArrow} key={i} style={{ left: i * 15 }} />
                ))}
                {flowListInfo.map((survey, i) => {
                    return (
                        <div key={survey.surveyHandle} className={style.stepButton}>
                            <ModalButton
                                buttonContent={`Step ${i + 1}`}
                                modalTitle={`Step ${i + 1}`}
                                className="btn btn-default"
                                style={getButtonStyle(i)}
                                icon={survey.icon}
                                tooltip={survey.tooltip}
                                beforeShow={() => beforeShowSurvey(survey, i)}
                                beforeClose={() => onCloseSurvey(survey)}
                                afterClose={() => {
                                    setClosingModal(false);
                                    if (!props.orgTeamMemberEditingDisabled) {
                                        setShowReportOrSurvey('survey');
                                    }
                                }}
                                disabled={getButtonDisabled(flowListInfo, survey)}
                                closingModal={closingModal}
                            >
                                {survey.status === 'Complete' || showReportOrSurvey === 'report' ? (
                                    <div>
                                        {!props.orgTeamMemberEditingDisabled && (
                                            <div className={style.surveyCompleteButtons}>
                                                {survey.icon === 'review' && (
                                                    <Tooltip title="Because previous steps have changed, this step has been marked for review. Once you have reviewed this step, click here to mark it as reviewed.">
                                                        <Button
                                                            title="Mark as Reviewed"
                                                            isConfirm
                                                            confirmationMessage="Mark this step as reviewed?"
                                                            className="btn btn-primary"
                                                            onClick={() => {
                                                                updateReviewSteps(reviewSteps.filter(s => s !== i + 1));
                                                                updateHasReviewed(true);
                                                            }}
                                                        />
                                                    </Tooltip>
                                                )}
                                                <button
                                                    type="button"
                                                    className={`btn ${
                                                        showReportOrSurvey === 'report' ? 'btn-primary' : 'btn-default'
                                                    }`}
                                                    style={{ marginLeft: 'auto' }}
                                                    disabled={showReportOrSurvey === 'report'}
                                                    onClick={() => setShowReportOrSurvey('report')}
                                                >
                                                    View Report
                                                </button>
                                                <button
                                                    type="button"
                                                    className={`btn ${
                                                        showReportOrSurvey === 'survey' ? 'btn-primary' : 'btn-default'
                                                    }`}
                                                    disabled={showReportOrSurvey === 'survey'}
                                                    onClick={() => setShowReportOrSurvey('survey')}
                                                >
                                                    Edit Step
                                                </button>
                                            </div>
                                        )}
                                        {!loading && <div>{renderIfSurveyComplete(survey, i)}</div>}
                                    </div>
                                ) : (
                                    <SurveyPlayer
                                        deployment={props.deployment}
                                        surveyList={[survey.surveyHandle]}
                                        metricspawn={props.metricspawn}
                                        getUsersQuery={getUsersQuery}
                                        flowListIndex={i}
                                        step2ListVarsQuery={step2ListVarsQuery}
                                        step3ListVarsQuery={step3ListVarsQuery}
                                        step4ListVarsQuery={step4ListVarsQuery}
                                        step5ListVarsQuery={step5ListVarsQuery}
                                        setShowReportOrSurvey={setShowReportOrSurvey}
                                    />
                                )}
                            </ModalButton>
                        </div>
                    );
                })}
            </div>
        );
    }

    if (
        [
            getUsersQuery.isError,
            stepsAssignment.isError,
            props.assignmentProgressQuery.isError,
            step2AlertsQuery,
            step3AlertsQuery,
            step4AlertsQuery,
            step5AlertsQuery,
        ].some(x => x === true)
    ) {
        return (
            <div>
                {notification({
                    message: `An error has occurred.`,
                    type: 'danger',
                    duration: 8000,
                })}
            </div>
        );
    }
};

export default Survey;
