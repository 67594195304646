import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import Axios from 'cccisd-axios';
import ClickButton from 'cccisd-click-button';
import Confirm from 'cccisd-confirm';
import notification from 'cccisd-notification';
import IconArrowDown from 'cccisd-icons/arrow-down16';
import Tooltip from 'cccisd-tooltip';

var Boilerplate = window.cccisd && window.cccisd.boilerplate;
const Appdefs = window.cccisd && window.cccisd.appDefs;

class DemoteRole extends Component {
    static propTypes = {
        row: PropTypes.object,
        settings: PropTypes.object,
        loadData: PropTypes.func,
    };

    Demote = async () => {
        const { row, loadData } = this.props;
        const pawnId = _get(row, 'pawn.pawnId');
        const pawnHash = _get(row, 'pawn.pawnHash');
        const email = _get(row, 'user.email');

        let data = {
            pawnId,
            pawnHash,
        };

        let response = await Axios.post(Boilerplate.route('demote.pawn.role'), data);
        if (response && response.data && response.data.status === 'success') {
            notification({
                message: `${email} is now an Org Team Member.`,
                type: 'success',
                duration: 8000,
            });

            loadData();
        } else {
            notification({
                message: `Error demoting role for ${email}.`,
                data: response && response.data && response.data.errors,
                duration: -1,
                type: 'danger',
            });
        }
    };

    confirm = () => {
        const { row } = this.props;
        const email = _get(row, 'user.email');

        Confirm({
            message: `Are you sure you want to demote ${email}?`,
            description: `You are about to demote user ${email} from Org Admin to Org Team Member.`,
            confirmLabel: 'Demote Role',
            abortLabel: 'Cancel',
        })
            .then(() => {
                this.Demote();
            })
            .fail(() => {
                // close modal
            });
    };

    render() {
        const highContrast = _get(Appdefs, 'app.settings.highContrast', false);

        return (
            <Tooltip title="Demote to Org Team Member">
                <ClickButton
                    title={
                        <span>
                            <IconArrowDown />
                        </span>
                    }
                    className="btn btn-xs btn-warning"
                    style={highContrast ? { color: 'black' } : {}}
                    onClick={this.confirm}
                />
            </Tooltip>
        );
    }
}

export default DemoteRole;
