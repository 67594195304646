import React, { useState } from 'react';
import Loader from 'cccisd-loader';
import axios from 'cccisd-axios';
import Button from 'cccisd-click-button';
import Notification from 'cccisd-notification';
import IconArrowRight from 'cccisd-icons/arrow-right20';
import IconArrowDown from 'cccisd-icons/arrow-down20';
import style from './style.css';
import { useMetricspawnQuery, useAssignmentProgress } from 'js/queries';

const Boilerplate = window.cccisd.boilerplate;

const ArchivedProblems = props => {
    const [expanded, setExpanded] = useState(false);
    const metricspawnQuery = useMetricspawnQuery();
    const metricspawn = metricspawnQuery?.data?.descendantRoles?.metricspawn?.pawn;
    const assignmentProgressQuery = useAssignmentProgress('assignmentProgress', metricspawn?.pawnId);

    const isLoading = metricspawnQuery.isLoading || assignmentProgressQuery.isLoading;

    const allQueryDataReturned = metricspawnQuery.data && assignmentProgressQuery.data;

    const unarchiveProblem = async deployment => {
        const problemData = {
            sourceId: deployment.assignmentId,
            sourceType: 'assignment',
            values: JSON.stringify({ problem_archived: 0 }),
            pawnId: metricspawn.pawnId,
            pawnHash: metricspawn.pawnHash,
            assignmentProgressId: deployment.assignmentProgressId,
            responseSet: deployment.deploymentId,
        };

        const response = await axios.post(Boilerplate.route('metrics.api.v1.data.bulkUpdate'), problemData);

        if (response) {
            // this.loadData();
            if (response.data.status === 'success') {
                const message = 'Problem has been unarchived.';
                Notification(message);
                await assignmentProgressQuery.refetch();
            } else {
                Notification({
                    message: 'Unable to unarchive the problem. Please contact your system administrator.',
                    type: 'danger',
                });
            }
        }
    };

    if (isLoading) {
        return <Loader loading />;
    }

    const getArchivedProblems = data => {
        return data.filter(p => p.archived.problem_archived === '1');
    };

    if (allQueryDataReturned) {
        const archivedProblems = getArchivedProblems(assignmentProgressQuery.data);
        return (
            <div className={style.container} style={expanded ? {} : {}}>
                <div className={style.header}>
                    <div className={style.title}>Archived Problems</div>
                    <button type="button" className="btn btn-default btn-sm" onClick={() => setExpanded(!expanded)}>
                        {expanded ? (
                            <>
                                Collapse <IconArrowDown />
                            </>
                        ) : (
                            <>
                                Expand <IconArrowRight />
                            </>
                        )}
                    </button>
                </div>
                <div className={style.cardContainer} style={expanded ? { height: '350px' } : { height: '0' }}>
                    {archivedProblems.map((a, i) => {
                        return (
                            <div key={a.problem.s1_problem_name || i} className={style.card}>
                                <div className={style.topBar}>
                                    <div style={{ fontSize: '1.2em', marginBottom: '0.5em' }}>
                                        {a.problem.s1_problem_name || (
                                            <div
                                                style={{
                                                    fontStyle: 'italic',
                                                    marginBottom: '0.5em',
                                                    color: 'grey',
                                                }}
                                            >
                                                Define a Problem in Step 1
                                            </div>
                                        )}
                                    </div>
                                    {!props.orgTeamMemberEditingDisabled && (
                                        <Button
                                            title="Unarchive"
                                            isConfirm
                                            confirmationMessage="Unarchive this problem?"
                                            className="btn btn-default btn-xs"
                                            onClick={() => unarchiveProblem(a)}
                                            style={{ height: '25px' }}
                                        />
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
};

export default ArchivedProblems;
