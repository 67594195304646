import React from 'react';
import { Interventions } from './filters';

export default [
    {
        name: 'intervention',
        label: 'Intervention',
        component: props => <Interventions {...props} />,
        // initialValue: [],
    },
];
