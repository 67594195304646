import React from 'react';
import style from './style.css';
import NoIcon from 'cccisd-icons/info2';
import YesIcon from 'cccisd-icons/checkmark-circle';

const Explanation = props => {
    const renderIcon = () => {
        if (props.data) {
            const answer = props.data[props.domain + '_yn'];
            if (answer === 'No') {
                return (
                    <span style={{ color: '#c5002e' }}>
                        <NoIcon />
                    </span>
                );
            }
            if (answer === 'Yes') {
                return (
                    <span style={{ color: 'green' }}>
                        <YesIcon />
                    </span>
                );
            }
            return '';
        }
        return '';
    };

    const getExplanationContent = () => {
        const noExplanationMessage = (
            <div style={{ padding: '0.5em', color: '#999' }}>NO EXPLANATION RECORDED</div>
        );
        if (!props.data) {
            return noExplanationMessage;
        }
        if (props.data[props.domain + '_explain'] === '') {
            return noExplanationMessage;
        }
        return props.data[props.domain + '_explain'];
    };

    return (
        <div className={style.wrapper}>
            <div className={style.youAnswered} style={{ textAlign: 'end' }}>
                {renderIcon()} You Answered{' '}
                <span style={{ fontWeight: 'bold' }}>{props.data[props.domain + '_yn']}</span>
            </div>
            <div className={style.topTitle}>EXPLANATION</div>
            <div className={style.textContent}>{getExplanationContent()}</div>
        </div>
    );
};

export default Explanation;
