import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'cccisd-react-router';
import Loader from 'cccisd-loader';
import { getUserGroups } from 'js/reducers/egto.js';
import RolePickerGroup from './RolePickerGroup';
import style from './style.css';

import providerIcon from './images/providerIcon.png';
import orgIcon from './images/orgIcon.png';

const mapStateToProps = (state, props) => ({
    userGroups: state.app.egto.userGroups,
});

export default connect(mapStateToProps, { getUserGroups })(
    class RolePicker extends React.Component {
        static propTypes = {
            userGroups: PropTypes.array,
            getUserGroups: PropTypes.func,
        };

        state = {
            loading: true,
        };

        componentDidMount = async () => {
            await this.props.getUserGroups();
            const userData = this.getUserData();

            this.setState({
                loading: false,
                userData,
            });
        };

        getUserData = () => {
            const { userGroups } = this.props;

            let guAdminPawns = userGroups.filter(user => user.data_type === 'guAdmin');
            let guTeamMemberPawns = userGroups.filter(user => user.data_type === 'guTeamMember');

            let guPawns = userGroups.filter(
                user => user.data_type === 'guAdmin' || user.data_type === 'guTeamMember'
            );

            let uberPawns = userGroups.filter(user => user.data_type === 'uberadmin');

            return {
                guAdminPawns,
                guTeamMemberPawns,
                guPawns,
                uberPawns,
            };
        };

        renderJoinButton = () => {
            const userTypes = this.props.userGroups.map(u => u.data_type);

            if (!userTypes.some(t => t === 'guAdmin' || t === 'guTeamMember')) {
                return (
                    <NavLink to="/signup">
                        <button type="button" className="btn btn-primary">
                            Register an Organization
                        </button>
                    </NavLink>
                );
            }
            return null;
        };

        render() {
            if (this.state.loading) {
                return <Loader loading />;
            }
            if (this.state.userData) {
                const { guPawns, uberPawns } = this.state.userData;

                return (
                    <div>
                        {guPawns.length > 0 || uberPawns.length > 0 ? (
                            <div
                                className={style.subheading}
                                style={{ display: 'flex', justifyContent: 'space-evenly' }}
                            >
                                <span className={style.subheadingTitle}>
                                    Please choose the account you would like to visit.
                                </span>
                                {/* <div>{this.renderJoinButton()}</div> */}
                            </div>
                        ) : (
                            <div className={style.subheading}>
                                <span className={style.subheadingTitle}>
                                    Your accounts will appear here.
                                </span>
                            </div>
                        )}
                        <div className={style.groupSelectWrapper}>
                            {guPawns.length >= 1 && (
                                <RolePickerGroup
                                    groupPawns={guPawns}
                                    backgroundImage={orgIcon}
                                    color="#859A20"
                                />
                            )}

                            {uberPawns.length >= 1 && (
                                <RolePickerGroup
                                    groupPawns={uberPawns}
                                    backgroundImage={providerIcon}
                                    color="#D32202"
                                />
                            )}
                        </div>
                    </div>
                );
            }
            return null;
        }
    }
);
