import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import Table from './table.js';
// import { getStep3ReportData } from 'js/reducers/egto.js';
import style from './style.css';

const Component = props => {
    // const dispatch = useDispatch();
    const reduxStore = useSelector(state => state.app.egto);

    // useEffect(() => {
    //     (async () => {
    //         await dispatch(getStep3ReportData());
    //     })();
    // }, []);

    return (
        <>
            {props.settings.show && (
                <div className={style.wrapper}>
                    <div className={style.header}>
                        <div className={style.headerLeft}>
                            <div className={style.headerIcon} />
                            {/* {props.settings.title || 'Table'} */}
                        </div>
                    </div>
                    <div className={style.content}>
                        {!reduxStore.step3ReportDataLoaded ? (
                            <Skeleton height={141} />
                        ) : (
                            <Table {...props} data={reduxStore.step3ReportData} />
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Component.defaultProps = {
    filters: {},
};

export default Component;
