import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import Loader from 'cccisd-loader';
import { client as apollo } from 'cccisd-apollo';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import axios from 'cccisd-axios';
import Modal from 'cccisd-modal';
import { AnswerSummary } from 'cccisd-laravel-appdefs/dist/reportTemplates';
import IconAdd from 'cccisd-icons/stack-plus';
import style from './style.css';
import _get from 'lodash/get';

import { useMetricspawnQuery, useGetStepsAssignmentQuery } from 'js/queries';
import groupAssignmentProgress from './groupAssignmentProgress.graphql';
import deploymentEvalAssignmentQuery from './deploymentQuery.graphql';

const appDefs = window.cccisd.appDefs;
const Boilerplate = window.cccisd.boilerplate;

const AddAProblem = props => {
    const [isLoading, setIsLoading] = useState(true);
    const [showReportOrSurvey, setShowReportOrSurvey] = useState('survey');
    const [deploymentId, setDeploymentId] = useState(1);
    const [closeModal, setCloseModal] = useState(false);
    const assignmentHandle = 'gto';
    const deploymentHandle = 'gto_deploy';
    const didMount = useRef(false);

    const onRefChange = useCallback(
        node => {
            // Dont run on mount
            if (didMount.current) {
                if (node === null) {
                    // DOM node referenced by ref has been unmounted
                } else {
                    // DOM node referenced by ref has changed and exists
                    closeModal ? node.close() : node.open();
                }
            } else didMount.current = true;
        },
        [deploymentId, closeModal]
    );

    useEffect(() => {
        setIsLoading(true);
        (async () => {
            const mostRecentDeployment = await getMostRecentDeployment();
            setDeploymentId(mostRecentDeployment.deploymentId);
            setIsLoading(false);
        })();
    }, []);

    const metricspawnQuery = useMetricspawnQuery();
    const metricspawnId = metricspawnQuery?.data?.descendantRoles?.metricspawn?.pawn?.pawnId;
    const respondentHash = metricspawnQuery?.data?.descendantRoles?.metricspawn?.pawn?.respondentHash;
    const metricspawnHash = metricspawnQuery?.data?.descendantRoles?.metricspawn?.pawn?.pawnHash;

    const stepsAssignment = useGetStepsAssignmentQuery(`stepsAssignment ${deploymentId}`, metricspawnId, deploymentId);

    const queryLoading = metricspawnQuery.isLoading || stepsAssignment.isLoading;
    const allQueryDataReturned = metricspawnQuery.data && stepsAssignment.data;

    const getAssignmentProgress = async () => {
        const result = await apollo.query({
            query: groupAssignmentProgress,
            fetchPolicy: 'network-only',
            variables: {
                pawnId: metricspawnId,
                assignmenthandle: assignmentHandle,
            },
        });

        const flattenedResult = result.data.roles.metricspawn.assignmentProgressList.map(ap => ({
            deploymentId: ap.deployment.deploymentId,
            timepoint: ap.deployment.timepoint,
            status: ap.status,
            ...ap,
        }));

        return flattenedResult;
    };

    const getMostRecentDeployment = async () => {
        let mostRecentDeployment;
        await getAssignmentProgress().then(async assignmentProgressList => {
            if (assignmentProgressList.length > 0) {
                if (assignmentProgressList.some(d => d.timepoint)) {
                    mostRecentDeployment = assignmentProgressList.reduce((max, current) =>
                        max.timepoint > current.timepoint ? max : current
                    );
                }
            }

            if (!mostRecentDeployment) {
                const response = await apollo.query({
                    query: deploymentEvalAssignmentQuery,
                    fetchPolicy: 'network-only',
                    variables: {
                        deploymentHandle,
                    },
                });
                const deployment = _get(response, 'data.flows.deployment', {});
                mostRecentDeployment = {
                    deploymentId: deployment.deploymentId,
                    status: 'In Progress',
                };
            }
        });
        return mostRecentDeployment;
    };

    /**
     * Get the parent deployment for this domain for this user role.
     *
     *
     * @return {[type]} [description]
     */
    const evaluateDeployment = async () => {
        setIsLoading(true);
        const response = await apollo.query({
            query: deploymentEvalAssignmentQuery,
            fetchPolicy: 'network-only',
            variables: {
                deploymentHandle,
            },
        });

        const deployment = _get(response, 'data.flows.deployment', {});
        await repeatedDeployment(deployment);
        setIsLoading(false);
    };

    /**
     * All Domain deployments are 'repeated'.  User can take the deployment
     * as many times and each time is a new 'child' deployment id.  In these cases,
     * we need to call the assignment repeated route to determine the next available
     * child deployment id. If this is a resume, the route will return the deployment
     * id to continue.
     *
     * The Domain deployments are always open.
     *
     * Users must be logged in to access the deployments.
     *
     * @param {object} deployment
     */
    const repeatedDeployment = async deployment => {
        let response = await axios.get(
            Boilerplate.route('api.assignmentDeployment.repeated', {
                deploymentId: deployment.deploymentId,
                pawnId: metricspawnId,
                hash: respondentHash,
            }),
            { params: { forceRepeat: true } }
        );
        const responseDeploymentId = _get(response, 'data.data.deploymentId');
        if (responseDeploymentId) {
            setDeploymentId(responseDeploymentId);
        }
    };

    const onAddAProblem = async () => {
        await evaluateDeployment();
    };

    const getReportSettings = () => {
        const reportList = appDefs.reports.list;
        const anyReportWithId = reportList.find(r => r.id === 'PriorityProblemSummary');
        return anyReportWithId.reportTemplateSettings;
    };

    if (isLoading || queryLoading) {
        return <Loader loading />;
    }

    if (allQueryDataReturned) {
        const flows = stepsAssignment.data.data?.flows.deployment.assignment.surveyList;
        return (
            <>
                {deploymentId && (
                    <Modal
                        size="100%"
                        contentStyle={{ width: 'fit-content' }}
                        ref={onRefChange}
                        trigger={null}
                        beforeClose={() => {
                            props.refetch();
                            setCloseModal(false);
                        }}
                        headerStyle={{ display: 'none' }}
                    >
                        <div
                            style={{
                                borderRadius: '6px 6px 0 0',
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '1em',
                                paddingBottom: '0.5em',
                                borderBottom: '1px solid #eee',
                            }}
                        >
                            <h4 className="modal-title" style={{ flex: '1' }}>
                                <div>Step 1</div>
                            </h4>
                            <button type="button" className="btn btn-primary" onClick={() => setCloseModal(true)}>
                                Return to Planning Page
                            </button>
                        </div>
                        {showReportOrSurvey === 'report' && !props.orgTeamMemberEditingDisabled && (
                            <div className={style.surveyCompleteButtons}>
                                <button
                                    type="button"
                                    className={`btn ${showReportOrSurvey === 'report' ? 'btn-primary' : 'btn-default'}`}
                                    style={{ marginLeft: 'auto' }}
                                    disabled={showReportOrSurvey === 'report'}
                                    onClick={() => setShowReportOrSurvey('report')}
                                >
                                    View Report
                                </button>
                                <button
                                    type="button"
                                    className={`btn ${showReportOrSurvey === 'survey' ? 'btn-primary' : 'btn-default'}`}
                                    disabled={showReportOrSurvey === 'survey'}
                                    onClick={() => setShowReportOrSurvey('survey')}
                                >
                                    Edit Step
                                </button>
                            </div>
                        )}
                        {showReportOrSurvey === 'survey' ? (
                            <DeploymentPlayer
                                deploymentId={deploymentId}
                                pawnId={metricspawnId}
                                pawnHash={metricspawnHash}
                                assignmentOptions={[flows[0]?.surveyHandle]}
                                onFlowComplete={() => setShowReportOrSurvey('report')}
                            />
                        ) : (
                            <div className={style.centerReport}>
                                <AnswerSummary.player settings={getReportSettings()} pawnId={metricspawnId} />
                            </div>
                        )}
                    </Modal>
                )}
                <div className={style.addAProblemBox}>
                    <button type="button" className={`btn ${style.purpleButton}`} onClick={onAddAProblem}>
                        <IconAdd /> Add a problem
                    </button>
                </div>
            </>
        );
    }
};

AddAProblem.propTypes = {
    problems: PropTypes.array,
    setProblems: PropTypes.func,
};

AddAProblem.defaultProps = {};

export default AddAProblem;
