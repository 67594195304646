import React from 'react';
import { Page, BrowserOnly } from 'cccisd-laravel-report';
import Loader from 'cccisd-loader';
import Header from './ReportHeader';
import useGetLogicModelData from './useGetLogicModelData';
import { getFlowListInfo } from 'js/components/Survey/helpers.js';
import { getAlertsInfo } from './helpers.js';
import { stepColors } from '../PlanningBox/stepBoxes.js';
import style from './style.css';
import IconNotification from 'cccisd-icons/notification';
import WarningCard from './WarningCard.js';
import ArrowIcon from 'cccisd-icons/arrow-right6';

const LogicModelPage = props => {
    const logicModelData = useGetLogicModelData({
        deployment: props.deployment,
        metricspawn: props.metricspawn,
    });
    if (logicModelData.loading || logicModelData.isLoading || !logicModelData.allQueryDataReturned) {
        return <Loader loading={logicModelData.loading} />;
    }

    const badge = (title, stepNumber, badgeStyle) => {
        return (
            <div
                className={`label ${style.label}`}
                style={{
                    backgroundColor: stepColors[stepNumber],
                    marginRight: '0.3em',
                    ...badgeStyle,
                }}
            >
                {title}
            </div>
        );
    };

    const flows = logicModelData.stepsAssignment.data.data?.flows.deployment.assignment.surveyList;
    const flowListInfo = getFlowListInfo({
        flowList: flows,
        step2AlertsQuery: logicModelData.step2AlertsQuery,
        thereIsStep2Progress: logicModelData.thereIsStep2Progress,
        step3AlertsQuery: logicModelData.step3AlertsQuery,
        thereIsStep3Progress: logicModelData.thereIsStep3Progress,
        step4AlertsQuery: logicModelData.step4AlertsQuery,
        thereIsStep4Progress: logicModelData.thereIsStep4Progress,
        step5AlertsQuery: logicModelData.step5AlertsQuery,
        thereIsStep5Progress: logicModelData.thereIsStep5Progress,
        deploymentData: props.deployment,
    });

    const alertsInfo = getAlertsInfo(flowListInfo);
    const renderTableContent = () => {
        return (
            <>
                {logicModelData.outcomesByGoal.length > 0 ? (
                    logicModelData.outcomesByGoal.map((goal, i) => {
                        if (goal.outcomes.length > 0) {
                            return (
                                <Page key={goal + i}>
                                    <table
                                        style={{
                                            borderCollapse: 'collapse',
                                            border: '3px solid #353863',
                                            color: '#353863',
                                            tableLayout: 'fixed',
                                            width: '100%',
                                        }}
                                    >
                                        <thead>
                                            <tr>
                                                <th style={{ width: '100%' }} colSpan="4" scope="colgroup">
                                                    <Header problem={props.problem} alerts={alertsInfo} />
                                                </th>
                                            </tr>
                                            <tr>
                                                <th style={{ width: '100%' }} colSpan="4" scope="colgroup">
                                                    <div
                                                        style={{
                                                            fontSize: 'large',
                                                            textAlign: 'center',
                                                            backgroundColor: '#353863',
                                                            color: '#fff',
                                                            padding: '0.5em',
                                                        }}
                                                    >
                                                        {badge('Step 2', '2', {
                                                            border: '1px solid #fff',
                                                            fontSize: '12px',
                                                        })}{' '}
                                                        Goal: {goal.title}
                                                    </div>
                                                </th>
                                            </tr>
                                            <tr style={{ backgroundColor: '#353863' }}>
                                                <th scope="col" style={{ width: '25%' }}>
                                                    <div
                                                        style={{
                                                            backgroundColor: '#f1f6fd',
                                                            margin: '0 0.3em',
                                                            padding: '0.4em',
                                                            clipPath:
                                                                'polygon(95% 0, 100% 50%, 95% 100%, 0% 100%, 0 50%, 0% 0%)',
                                                            marginLeft: '0',
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        <div style={{ marginBottom: '0.3em' }}>
                                                            {badge('Step 2', '2')}
                                                        </div>
                                                        <div style={{ fontWeight: 'bold' }}>Desired Outcome</div>
                                                    </div>
                                                </th>
                                                <th scope="col" style={{ width: '25%' }}>
                                                    <div
                                                        style={{
                                                            backgroundColor: '#f1f6fd',
                                                            clipPath:
                                                                'polygon(95% 0, 100% 50%, 95% 100%, 0% 100%, 5% 50%, 0% 0%)',
                                                            margin: '0 0.3em',
                                                            padding: '0.4em',
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        <div style={{ marginBottom: '0.3em' }}>
                                                            {badge('Step 3', '3')}
                                                        </div>
                                                        <div style={{ fontWeight: 'bold' }}>Intervention</div>
                                                    </div>
                                                </th>
                                                <th scope="col" style={{ width: '25%' }}>
                                                    <div
                                                        style={{
                                                            backgroundColor: '#f1f6fd',
                                                            clipPath:
                                                                'polygon(95% 0, 100% 50%, 95% 100%, 0% 100%, 5% 50%, 0% 0%)',
                                                            margin: '0 0.3em',
                                                            padding: '0.4em',
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        <div style={{ marginBottom: '0.3em' }}>
                                                            {badge('Step 6', '6')}
                                                        </div>
                                                        <div style={{ fontWeight: 'bold' }}>
                                                            Process Evaluation Measure
                                                        </div>
                                                    </div>
                                                </th>
                                                <th scope="col" style={{ width: '25%' }}>
                                                    <div
                                                        style={{
                                                            backgroundColor: '#f1f6fd',
                                                            margin: '0 0.3em',
                                                            padding: '0.4em',
                                                            clipPath:
                                                                'polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 5% 50%, 0% 0%)',
                                                            marginRight: '0',
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        <div style={{ marginBottom: '0.3em' }}>
                                                            {badge('Step 6', '6')}
                                                        </div>
                                                        <div
                                                            style={{
                                                                fontWeight: 'bold',
                                                            }}
                                                        >
                                                            Outcome Evaluation Measure
                                                        </div>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody style={{ backgroundColor: '#f1f6fd' }}>
                                            {goal.outcomes.map(outcome => {
                                                return outcome.interventions.map((intervention, j) => {
                                                    return (
                                                        <tr
                                                            style={{ fontSize: '14px' }}
                                                            key={outcome + intervention + j}
                                                        >
                                                            {j === 0 ? (
                                                                <td
                                                                    rowSpan={outcome.interventions.length}
                                                                    className={style.td}
                                                                    style={{
                                                                        border: '1px solid #353863',
                                                                        padding: '0.5em 1em',
                                                                        color: '#353863',
                                                                        verticalAlign: 'top',
                                                                    }}
                                                                >
                                                                    {outcome.title ? (
                                                                        <div
                                                                            style={{
                                                                                display: 'flex',
                                                                                gap: '14px',
                                                                            }}
                                                                        >
                                                                            <div>
                                                                                <ArrowIcon spaceRight />
                                                                            </div>
                                                                            <div>{outcome.title}</div>
                                                                        </div>
                                                                    ) : (
                                                                        <BrowserOnly>
                                                                            <div className={style.placeholder}>
                                                                                <IconNotification /> Create SMART
                                                                                Outcomes in Step 2
                                                                            </div>
                                                                        </BrowserOnly>
                                                                    )}
                                                                </td>
                                                            ) : null}
                                                            <td
                                                                className={style.td}
                                                                style={{
                                                                    border: '1px solid #353863',
                                                                    padding: '0.5em 1em',
                                                                    color: '#353863',
                                                                    verticalAlign: 'top',
                                                                }}
                                                            >
                                                                {intervention.title ? (
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            gap: '14px',
                                                                        }}
                                                                    >
                                                                        <div>
                                                                            <ArrowIcon spaceRight />
                                                                        </div>
                                                                        <div>{intervention.title}</div>
                                                                    </div>
                                                                ) : (
                                                                    <BrowserOnly>
                                                                        <div className={style.placeholder}>
                                                                            <IconNotification /> Enter Interventions in
                                                                            Step 3 and Proceed with them in Step 5
                                                                        </div>
                                                                    </BrowserOnly>
                                                                )}
                                                            </td>
                                                            <td
                                                                className={style.td}
                                                                style={{
                                                                    border: '1px solid #353863',
                                                                    padding: '0.5em 1em',
                                                                    color: '#353863',
                                                                    verticalAlign: 'top',
                                                                }}
                                                            >
                                                                {outcome.processEvalMeasures[intervention.id]?.length >
                                                                0 ? (
                                                                    <>
                                                                        {outcome.processEvalMeasures[
                                                                            intervention.id
                                                                        ].map((measure, measureIndex) => {
                                                                            return (
                                                                                <div
                                                                                    style={{
                                                                                        display: 'flex',
                                                                                        gap: '14px',
                                                                                    }}
                                                                                    key={measure}
                                                                                >
                                                                                    <div>
                                                                                        {measureIndex === 0 ? (
                                                                                            <ArrowIcon spaceRight />
                                                                                        ) : (
                                                                                            <>• &nbsp; &nbsp;</>
                                                                                        )}{' '}
                                                                                    </div>
                                                                                    <div>{measure}</div>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </>
                                                                ) : (
                                                                    <BrowserOnly>
                                                                        <div className={style.placeholder}>
                                                                            <IconNotification /> Select Process
                                                                            Evaluation Measures in Step 6
                                                                        </div>
                                                                    </BrowserOnly>
                                                                )}
                                                            </td>
                                                            <td
                                                                className={style.td}
                                                                style={{
                                                                    border: '1px solid #353863',
                                                                    padding: '0.5em 1em',
                                                                    color: '#353863',
                                                                    verticalAlign: 'top',
                                                                }}
                                                            >
                                                                {outcome.outcomeEvalMeasures?.length > 0 ? (
                                                                    <>
                                                                        {outcome.outcomeEvalMeasures.map(
                                                                            (oem, oemIndex) => {
                                                                                return (
                                                                                    <div
                                                                                        style={{
                                                                                            display: 'flex',
                                                                                            gap: '14px',
                                                                                        }}
                                                                                        key={oem}
                                                                                    >
                                                                                        <div>
                                                                                            {oemIndex === 0 ? (
                                                                                                <ArrowIcon spaceRight />
                                                                                            ) : (
                                                                                                <>• &nbsp; &nbsp;</>
                                                                                            )}{' '}
                                                                                        </div>
                                                                                        <div>{oem}</div>
                                                                                    </div>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    <BrowserOnly>
                                                                        <div className={style.placeholder}>
                                                                            <IconNotification /> Enter Outcome
                                                                            Evaluation Measures in Step 6
                                                                        </div>
                                                                    </BrowserOnly>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    );
                                                });
                                            })}
                                        </tbody>
                                    </table>
                                </Page>
                            );
                        }
                        return (
                            <BrowserOnly key={goal + i}>
                                <WarningCard problem={props.problem} />
                            </BrowserOnly>
                        );
                    })
                ) : (
                    <BrowserOnly>
                        <WarningCard problem={props.problem} />
                    </BrowserOnly>
                )}
            </>
        );
    };

    return renderTableContent();
};

export default LogicModelPage;
