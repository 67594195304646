export const getAlertsInfo = data => {
    const steps3Through6Data = data.filter(s => ![1, 2].includes(s.surveyId));
    const stepsWithAlerts = steps3Through6Data.filter(s => s.icon === 'alert');

    if (stepsWithAlerts.length > 0) {
        return stepsWithAlerts.map(x => ({ icon: 'alert', tooltip: x.tooltip }));
    }
    return '';
};

export const objToArray = obj => {
    return Object.keys(obj).map(key => obj[key]);
};

export const filterStep5Proceed = (obj, prefix, suffix, step5Data, step5Progress) => {
    const newObj = { ...obj };
    if (step5Progress) {
        if (newObj) {
            ['i1', 'i2', 'i3', 'i4', 'i5', 'i6'].forEach(x => {
                if (step5Data['s5_' + x + '_proceed'] !== '0') {
                    newObj[prefix + x + suffix] = null;
                }
            });
        }
    }
    return newObj;
};
