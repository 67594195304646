import React from 'react';
import Explanation from './Cell/explanation.js';
import Changes from './Cell/changes.js';
// import testData from './testData.js';
import style from './style.css';
import IconInfo from 'cccisd-icons/info2';

const table = props => {
    // console.log('table props ', props);
    const getInterventionData = () => {
        let data = [];
        const thereIsReportData = Object.keys(props.data).length > 0;
        const thereAreValidFilters = typeof props.filters.intervention === 'object';
        // console.log('table filters ', props.filters);
        if (thereIsReportData) {
            if (thereAreValidFilters) {
                props.filters.intervention.forEach(i => {
                    data.push(props.data[i.value]);
                });
            }
            return data;
        }
        return data;
    };

    const data = getInterventionData();
    // console.log(data);
    if (data.length < 1) {
        return (
            <div className="alert alert-info" role="alert" style={{ fontSize: '1.5em' }}>
                <IconInfo /> &nbsp;&nbsp; Select up to three interventions to see a comparison
                report.
            </div>
        );
    }

    return (
        <table className={style.tg} style={{ width: '100%' }}>
            <colgroup>
                <col style={{ width: '14%' }} />
                <col style={{ width: `${86 / data.length}%` }} />
                <col style={{ width: `${86 / data.length}%` }} />
                <col style={{ width: `${86 / data.length}%` }} />
            </colgroup>
            <thead>
                <tr>
                    <th className={style.tgHeading} />
                    {data[0] && <th className={style.tgHeading}>{data[0] && data[0].name}</th>}
                    {data[1] && <th className={style.tgHeading}>{data[1] && data[1].name}</th>}
                    {data[2] && <th className={style.tgHeading}>{data[2] && data[2].name}</th>}
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`} rowSpan="2">
                        Evidence of Effectiveness?
                    </td>
                    {data[0] && (
                        <td className={`${style.tgCell} ${style.explanation}`}>
                            <Explanation data={data[0]} domain="eoe" />
                        </td>
                    )}
                    {data[1] && (
                        <td className={`${style.tgCell} ${style.explanation}`}>
                            <Explanation data={data[1]} domain="eoe" />
                        </td>
                    )}
                    {data[2] && (
                        <td className={`${style.tgCell} ${style.explanation}`}>
                            <Explanation data={data[2]} domain="eoe" />
                        </td>
                    )}
                </tr>
                <tr>
                    {data[0] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[0]} domain="eoe" />
                        </td>
                    )}
                    {data[1] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[1]} domain="eoe" />
                        </td>
                    )}
                    {data[2] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[2]} domain="eoe" />
                        </td>
                    )}
                </tr>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`} rowSpan="2">
                        Focus on goals and outcomes?
                    </td>
                    {data[0] && (
                        <td className={`${style.tgCell} ${style.explanation}`}>
                            <Explanation data={data[0]} domain="focus" />
                        </td>
                    )}
                    {data[1] && (
                        <td className={`${style.tgCell} ${style.explanation}`}>
                            <Explanation data={data[1]} domain="focus" />
                        </td>
                    )}
                    {data[2] && (
                        <td className={`${style.tgCell} ${style.explanation}`}>
                            <Explanation data={data[2]} domain="focus" />
                        </td>
                    )}
                </tr>
                <tr>
                    {data[0] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[0]} domain="focus" />
                        </td>
                    )}
                    {data[1] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[1]} domain="focus" />
                        </td>
                    )}
                    {data[2] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[2]} domain="focus" />
                        </td>
                    )}
                </tr>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`} rowSpan="2">
                        Implementation support materials?
                    </td>
                    {data[0] && (
                        <td className={`${style.tgCell} ${style.explanation}`}>
                            <Explanation data={data[0]} domain="implementation" />
                        </td>
                    )}
                    {data[1] && (
                        <td className={`${style.tgCell} ${style.explanation}`}>
                            <Explanation data={data[1]} domain="implementation" />
                        </td>
                    )}
                    {data[2] && (
                        <td className={`${style.tgCell} ${style.explanation}`}>
                            <Explanation data={data[2]} domain="implementation" />
                        </td>
                    )}
                </tr>
                <tr>
                    {data[0] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[0]} domain="implementation" />
                        </td>
                    )}
                    {data[1] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[1]} domain="implementation" />
                        </td>
                    )}
                    {data[2] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[2]} domain="implementation" />
                        </td>
                    )}
                </tr>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`} rowSpan="2">
                        Teaching strategies involving participants?
                    </td>
                    {data[0] && (
                        <td className={`${style.tgCell} ${style.explanation}`}>
                            <Explanation data={data[0]} domain="teaching" />
                        </td>
                    )}
                    {data[1] && (
                        <td className={`${style.tgCell} ${style.explanation}`}>
                            <Explanation data={data[1]} domain="teaching" />
                        </td>
                    )}
                    {data[2] && (
                        <td className={`${style.tgCell} ${style.explanation}`}>
                            <Explanation data={data[2]} domain="teaching" />
                        </td>
                    )}
                </tr>
                <tr>
                    {data[0] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[0]} domain="teaching" />
                        </td>
                    )}
                    {data[1] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[1]} domain="teaching" />
                        </td>
                    )}
                    {data[2] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[2]} domain="teaching" />
                        </td>
                    )}
                </tr>
                <tr>
                    <td className={`${style.tgCell} ${style.domain}`} rowSpan="2">
                        Activities, methods, and messages that are appropriate for the target
                        population?
                    </td>
                    {data[0] && (
                        <td className={`${style.tgCell} ${style.explanation}`}>
                            <Explanation data={data[0]} domain="target" />
                        </td>
                    )}
                    {data[1] && (
                        <td className={`${style.tgCell} ${style.explanation}`}>
                            <Explanation data={data[1]} domain="target" />
                        </td>
                    )}
                    {data[2] && (
                        <td className={`${style.tgCell} ${style.explanation}`}>
                            <Explanation data={data[2]} domain="target" />
                        </td>
                    )}
                </tr>
                <tr>
                    {data[0] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[0]} domain="target" />
                        </td>
                    )}
                    {data[1] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[1]} domain="target" />
                        </td>
                    )}
                    {data[2] && (
                        <td className={`${style.tgCell} ${style.changes}`}>
                            <Changes data={data[2]} domain="target" />
                        </td>
                    )}
                </tr>
            </tbody>
        </table>
    );
};

export default table;
