import React from 'react';
import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import _pick from 'lodash/pick';
import { domainKeyTitles } from './helpers.js';

const SurveyPlayer = props => {
    const {
        metricspawn,
        getUsersQuery,
        step2ListVarsQuery,
        step3ListVarsQuery,
        step4ListVarsQuery,
        step5ListVarsQuery,
    } = props;

    const objToArray = obj => {
        return Object.keys(obj).map(key => obj[key]);
    };

    /*
     *   Turn metrics into variables to pass to flow
     *   metrics that use this: Outcomes, Adaptations, and Tasks
     */
    const getVarsList = (data, addDomainName) => {
        const dataCopy = JSON.parse(JSON.stringify(data));
        // If we want to append the domain name to the beginning
        //  of the adaptation / task
        if (addDomainName) {
            Object.keys(dataCopy).forEach(domainTaskKey => {
                const domainKeySplit = domainTaskKey.split('_');
                let domainKey = '';
                if (domainKeySplit.indexOf('fit') !== -1) {
                    domainKey = domainKeyTitles[domainKeySplit[domainKeySplit.indexOf('fit') + 1]];
                }
                if (domainKeySplit.indexOf('cap') !== -1) {
                    domainKey =
                        domainKeyTitles[domainKeySplit[domainKeySplit.indexOf('cap') + 1]][
                            domainKeySplit[domainKeySplit.indexOf('cap') + 2]
                        ];
                }
                if (dataCopy[domainTaskKey]) {
                    dataCopy[domainTaskKey] = domainKey + ': ' + dataCopy[domainTaskKey];
                }
            });
        }
        // When passing to flow variables / defaultValueVariables,
        //  need to have this data shape
        const varsList = objToArray(dataCopy)
            .map((g, i) => {
                if (g && ![': MetricsDevTags', 'MetricsDevTags'].includes(g)) {
                    return {
                        id: i + 1,
                        label: g,
                        name: g,
                    };
                }
                return null;
            })
            .filter(Boolean);
        return varsList;
    };

    const filterStep5Proceed = (obj, prefix, suffix) => {
        const interventionsProceed = step5ListVarsQuery.data.data.roles.metricspawn.assignmentProgress.proceed;
        const newObj = { ...obj };
        if (newObj) {
            ['i1', 'i2', 'i3', 'i4', 'i5', 'i6'].forEach(x => {
                if (interventionsProceed['s5_' + x + '_proceed'] !== '0') {
                    newObj[prefix + x + suffix] = null;
                }
            });
        }

        return newObj;
    };

    const getFlowProps = () => {
        const { flowListIndex } = props;
        if (flowListIndex === 2) {
            return {
                goals: getVarsList(step2ListVarsQuery.data.data.roles.metricspawn.assignmentProgress.goals),
                outcomes: getVarsList(step2ListVarsQuery.data.data.roles.metricspawn.assignmentProgress.outcomes),
            };
        }
        if ([3, 4].includes(flowListIndex)) {
            return {
                interventions: getVarsList(
                    step3ListVarsQuery.data.data.roles.metricspawn.assignmentProgress.interventions
                ),
            };
        }
        if (flowListIndex === 5) {
            const outcomes = step3ListVarsQuery.data.data.roles.metricspawn.assignmentProgress.selectedOutcomes;
            const filteredOutcomes = filterStep5Proceed(outcomes, 's3_', '_selected_outcomes');

            return {
                user_list: getUsersQuery.data.data.roles.anyRoleList.map(u => ({
                    id: u.user.username,
                    name: u.user.fullName,
                })),
                interventions: getVarsList(
                    step3ListVarsQuery.data.data.roles.metricspawn.assignmentProgress.interventions
                ),
                selectedGoals: getVarsList(
                    step3ListVarsQuery.data.data.roles.metricspawn.assignmentProgress.selectedGoals
                ),
                selectedOutcomes: getVarsList(filteredOutcomes),
                /*
                 *   Below keys are DEPRECATED until next comment
                 *       Tasks sorted by domain are no longer used.
                 *       Left here in case needed in the future, or
                 *       In case I missed them being used for the time being.
                 */
                targetAdaptations: getVarsList(
                    step4ListVarsQuery.data.data.roles.metricspawn.assignmentProgress.targetAdaptations
                ),
                communityAdaptations: getVarsList(
                    step4ListVarsQuery.data.data.roles.metricspawn.assignmentProgress.communityAdaptations
                ),
                orgAdaptations: getVarsList(
                    step4ListVarsQuery.data.data.roles.metricspawn.assignmentProgress.orgAdaptations
                ),
                staffChanges: getVarsList(
                    step5ListVarsQuery.data.data.roles.metricspawn.assignmentProgress.staffChanges
                ),
                commitmentChanges: getVarsList(
                    step5ListVarsQuery.data.data.roles.metricspawn.assignmentProgress.commitmentChanges
                ),
                communicationChanges: getVarsList(
                    step5ListVarsQuery.data.data.roles.metricspawn.assignmentProgress.communicationChanges
                ),
                techChanges: getVarsList(step5ListVarsQuery.data.data.roles.metricspawn.assignmentProgress.techChanges),
                materialChanges: getVarsList(
                    step5ListVarsQuery.data.data.roles.metricspawn.assignmentProgress.materialChanges
                ),
                collabChanges: getVarsList(
                    step5ListVarsQuery.data.data.roles.metricspawn.assignmentProgress.collabChanges
                ),
                /*
                 *   The following keys are used, passed to Step 6 defaulValueVariable
                 *       Adaptations / Tasks sorted by Intervention:
                 */
                intervention1Adaptations: getVarsList(
                    step4ListVarsQuery.data.data.roles.metricspawn.assignmentProgress.intervention1Adaptations,
                    true
                ),
                intervention2Adaptations: getVarsList(
                    step4ListVarsQuery.data.data.roles.metricspawn.assignmentProgress.intervention2Adaptations,
                    true
                ),
                intervention3Adaptations: getVarsList(
                    step4ListVarsQuery.data.data.roles.metricspawn.assignmentProgress.intervention3Adaptations,
                    true
                ),
                intervention4Adaptations: getVarsList(
                    step4ListVarsQuery.data.data.roles.metricspawn.assignmentProgress.intervention4Adaptations,
                    true
                ),
                intervention5Adaptations: getVarsList(
                    step4ListVarsQuery.data.data.roles.metricspawn.assignmentProgress.intervention5Adaptations,
                    true
                ),
                intervention6Adaptations: getVarsList(
                    step4ListVarsQuery.data.data.roles.metricspawn.assignmentProgress.intervention6Adaptations,
                    true
                ),
                intervention1Changes: getVarsList(
                    step5ListVarsQuery.data.data.roles.metricspawn.assignmentProgress.intervention1Changes,
                    true
                ),
                intervention2Changes: getVarsList(
                    step5ListVarsQuery.data.data.roles.metricspawn.assignmentProgress.intervention2Changes,
                    true
                ),
                intervention3Changes: getVarsList(
                    step5ListVarsQuery.data.data.roles.metricspawn.assignmentProgress.intervention3Changes,
                    true
                ),
                intervention4Changes: getVarsList(
                    step5ListVarsQuery.data.data.roles.metricspawn.assignmentProgress.intervention4Changes,
                    true
                ),
                intervention5Changes: getVarsList(
                    step5ListVarsQuery.data.data.roles.metricspawn.assignmentProgress.intervention5Changes,
                    true
                ),
                intervention6Changes: getVarsList(
                    step5ListVarsQuery.data.data.roles.metricspawn.assignmentProgress.intervention6Changes,
                    true
                ),
            };
        }
    };

    const getInitialValues = () => {
        const { flowListIndex } = props;

        const initialValues = {
            selectedOutcomes: {
                userRows: [],
            },
            allInterventionTasks: {
                userRows: [],
            },
            intervention1Tasks: {
                userRows: [],
            },
            intervention2Tasks: {
                userRows: [],
            },
            intervention3Tasks: {
                userRows: [],
            },
            intervention4Tasks: {
                userRows: [],
            },
            intervention5Tasks: {
                userRows: [],
            },
            intervention6Tasks: {
                userRows: [],
            },
        };

        if (flowListIndex === 5) {
            const flowProps = getFlowProps();
            const allTasks = _pick(flowProps, [
                'targetAdaptations',
                'communityAdaptations',
                'orgAdaptations',
                'staffChanges',
                'commitmentChanges',
                'communicationChanges',
                'techChanges',
                'materialChanges',
                'collabChanges',
            ]);
            const i1Tasks = _pick(flowProps, ['intervention1Adaptations', 'intervention1Changes']);
            const i2Tasks = _pick(flowProps, ['intervention2Adaptations', 'intervention2Changes']);
            const i3Tasks = _pick(flowProps, ['intervention3Adaptations', 'intervention3Changes']);
            const i4Tasks = _pick(flowProps, ['intervention4Adaptations', 'intervention4Changes']);
            const i5Tasks = _pick(flowProps, ['intervention5Adaptations', 'intervention5Changes']);
            const i6Tasks = _pick(flowProps, ['intervention6Adaptations', 'intervention6Changes']);

            Object.keys(allTasks).forEach((key, index) =>
                initialValues.allInterventionTasks.userRows.push(...allTasks[key])
            );
            Object.keys(i1Tasks).forEach((key, index) =>
                initialValues.intervention1Tasks.userRows.push(...i1Tasks[key])
            );
            Object.keys(i2Tasks).forEach((key, index) =>
                initialValues.intervention2Tasks.userRows.push(...i2Tasks[key])
            );
            Object.keys(i3Tasks).forEach((key, index) =>
                initialValues.intervention3Tasks.userRows.push(...i3Tasks[key])
            );
            Object.keys(i4Tasks).forEach((key, index) =>
                initialValues.intervention4Tasks.userRows.push(...i4Tasks[key])
            );
            Object.keys(i5Tasks).forEach((key, index) =>
                initialValues.intervention5Tasks.userRows.push(...i5Tasks[key])
            );
            Object.keys(i6Tasks).forEach((key, index) =>
                initialValues.intervention6Tasks.userRows.push(...i6Tasks[key])
            );

            // Convert this lists repeating ids into unique ids
            initialValues.allInterventionTasks.userRows.forEach((r, i) => {
                r.id = i;
                return i;
            });

            flowProps.selectedOutcomes.forEach(s => {
                const outcomesPerIntervention = JSON.parse(s.label);
                return outcomesPerIntervention.forEach((o, i) =>
                    initialValues.selectedOutcomes.userRows.push({ id: i, label: o })
                );
            });
        }

        return initialValues;
    };
    return (
        <DeploymentPlayer
            deploymentId={props.deployment.deploymentId}
            pawnId={metricspawn.pawnId}
            pawnHash={metricspawn.pawnHash}
            name="GTO"
            assignmentOptions={props.surveyList}
            onFlowComplete={() => {
                props.setShowReportOrSurvey('report');
            }}
            flowProps={{
                variables: getFlowProps(),
                defaultValueVariables: getInitialValues(),
            }}
        />
    );
};

export default SurveyPlayer;
