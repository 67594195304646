import { handleActions, createAction } from 'redux-actions';
import { client } from 'cccisd-apollo';
import axios from 'cccisd-axios';

import step3ReportQuery from './step3Report.graphql';
import step5ReportQuery from './step5Report.graphql';
import metricsPawnQuery from './getMetricsPawn.graphql';

import _mapKeys from 'lodash/mapKeys';
import _omit from 'lodash/omit';
import _every from 'lodash/every';
import _isEmpty from 'lodash/isEmpty';
// import _isNil from 'lodash/isNil';
import _omitBy from 'lodash/omitBy';
import _pick from 'lodash/pick';

// const Fortress = window.cccisd.fortress;
const Boilerplate = window.cccisd.boilerplate;

// Initial state
export const initialState = {
    step3ReportData: {},
    step3ReportDataLoaded: false,
    step3InterventionList: [{ label: '', value: '' }],
    step5ReportData: {},
    step5ReportDataLoaded: false,
    step5InterventionList: [{ label: '', value: '' }],
};

// Actions
const SET_METRICSPAWN = 'app/egto/SET_METRICSPAWN';
const SET_CURRENT_DEPLOYMENT = 'app/egto/SET_CURRENT_DEPLOYMENT';
const SET_USER_GROUPS = 'app/egto/SET_USER_GROUPS';
const SET_STEP3_REPORT_DATA = 'app/egto/SET_STEP3_REPORT_DATA';
const SET_STEP3_REPORT_DATA_LOADED = 'app/egto/SET_STEP3_REPORT_DATA_LOADED';
const SET_STEP3_INTERVENTION_LIST = 'app/egto/SET_STEP3_INTERVENTION_LIST';
const SET_STEP5_REPORT_DATA = 'app/egto/SET_STEP5_REPORT_DATA';
const SET_STEP5_REPORT_DATA_LOADED = 'app/egto/SET_STEP5_REPORT_DATA_LOADED';
const SET_STEP5_INTERVENTION_LIST = 'app/egto/SET_STEP5_INTERVENTION_LIST';

// Action Creators
export const setMetricsPawn = createAction(SET_METRICSPAWN);
export const setCurrentDeployment = createAction(SET_CURRENT_DEPLOYMENT);
export const setUserGroups = createAction(SET_USER_GROUPS);
export const setStep3ReportData = createAction(SET_STEP3_REPORT_DATA);
export const setStep3ReportDataLoaded = createAction(SET_STEP3_REPORT_DATA_LOADED);
export const setStep3InterventionList = createAction(SET_STEP3_INTERVENTION_LIST);
export const setStep5ReportData = createAction(SET_STEP5_REPORT_DATA);
export const setStep5ReportDataLoaded = createAction(SET_STEP5_REPORT_DATA_LOADED);
export const setStep5InterventionList = createAction(SET_STEP5_INTERVENTION_LIST);

export const getMetricsPawn = () => {
    return async (dispatch, getState) => {
        const result = await client.query({
            query: metricsPawnQuery,
            fetchPolicy: 'network-only',
        });

        const metricspawn = result.data.groups.anyGroup.descendantRoles.metricspawn;
        await dispatch(setMetricsPawn(metricspawn.pawn));
    };
};

export const getUserGroups = () => {
    return async (dispatch, getState) => {
        let response = await axios.get(Boilerplate.route('all.pawn.roles'));

        const userGroupData = response.data.roles.map(role => {
            return _pick(role, ['id', 'random_hash', 'data_type', 'group']);
        });
        await dispatch(setUserGroups(userGroupData));
    };
};

export const getStep3InterventionList = data => {
    const defaultList = [{ label: '', value: '' }];
    let list = [];
    if (data) {
        Object.keys(data).forEach(key => {
            if (data[key][`step3_${key}_name`]) {
                list.push({ label: data[key][`step3_${key}_name`], value: key });
            }
        });
    }
    if (list.length > 0) {
        return list;
    }
    return defaultList;
};

export const trimReportDataKeyNames = (data, trimLength = 9) => {
    // Trim off the 'step3_i#_' part of the key name, in each of the intervention objects
    // console.log('parseData input ', data);
    const newObj = {};
    Object.keys(data).forEach(iKey => {
        const newDataObj = _mapKeys(data[iKey], (value, jKey) => {
            const trimKeyName = jKey.slice(trimLength);
            return trimKeyName;
        });
        newObj[iKey] = newDataObj;
    });
    return newObj;
};

export const getStep3ReportData = (deploymentId, metricspawn) => {
    return async (dispatch, getState) => {
        dispatch(setStep3ReportDataLoaded(false));
        const response = await client.query({
            query: step3ReportQuery,
            variables: {
                pawnId: metricspawn?.pawnId || getState().app.egto.metricspawn,
                deploymentId,
            },
            fetchPolicy: 'network-only',
        });

        const step3ReportData = _omit(response.data.roles.metricspawn.assignmentProgress, '__typename');
        dispatch(setStep3ReportData(trimReportDataKeyNames(step3ReportData)));
        dispatch(setStep3InterventionList(getStep3InterventionList(step3ReportData)));
        dispatch(setStep3ReportDataLoaded(true));
    };
};

export const getStep5ReportData = (deploymentId, metricspawn) => {
    return async (dispatch, getState) => {
        dispatch(setStep5ReportDataLoaded(false));
        const response = await client.query({
            query: step5ReportQuery,
            variables: {
                pawnId: metricspawn?.pawnId || getState().app.egto.metricspawn,
                deploymentId,
            },
            fetchPolicy: 'network-only',
        });

        const step5ReportData = _omit(response.data.roles.metricspawn.assignmentProgress, '__typename');
        dispatch(setStep5ReportData(trimReportDataKeyNames(step5ReportData, 10)));
        await dispatch(getStep3ReportData(deploymentId, metricspawn)); // Run this to get intervention names/values for filter
        dispatch(setStep5ReportDataLoaded(true));
    };
};

export const getStep5InterventionList = (step3List, step5Data) => {
    return async (dispatch, getState) => {
        // Filter the interventions from step 3 based on if they have step 5 data
        let list = step3List;
        if (step5Data) {
            Object.keys(step5Data).forEach(key => {
                const filteredData = _omitBy(step5Data[key], (v, k) => v === 'MetricsDevTags');
                if (_every(filteredData, _isEmpty)) {
                    list = list.filter(l => {
                        return l.value !== key;
                    });
                }
            });
        }
        dispatch(setStep5InterventionList(list));
        // console.log('step5 List ', list);
        return list;
    };
};

// Reducer
export default handleActions(
    {
        [SET_METRICSPAWN]: (state, action) => ({
            ...state,
            metricspawn: action.payload,
        }),
        [SET_CURRENT_DEPLOYMENT]: (state, action) => ({
            ...state,
            currentDeployment: action.payload,
        }),
        [SET_USER_GROUPS]: (state, action) => ({
            ...state,
            userGroups: action.payload,
        }),
        [SET_STEP3_REPORT_DATA]: (state, action) => ({
            ...state,
            step3ReportData: action.payload,
        }),
        [SET_STEP3_REPORT_DATA_LOADED]: (state, action) => ({
            ...state,
            step3ReportDataLoaded: action.payload,
        }),
        [SET_STEP3_INTERVENTION_LIST]: (state, action) => ({
            ...state,
            step3InterventionList: action.payload,
        }),
        [SET_STEP5_REPORT_DATA]: (state, action) => ({
            ...state,
            step5ReportData: action.payload,
        }),
        [SET_STEP5_REPORT_DATA_LOADED]: (state, action) => ({
            ...state,
            step5ReportDataLoaded: action.payload,
        }),
        [SET_STEP5_INTERVENTION_LIST]: (state, action) => ({
            ...state,
            step5InterventionList: action.payload,
        }),
    },
    initialState
);
