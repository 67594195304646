import React from 'react';
import PropTypes from 'prop-types';
import Header from 'cccisd-header';
import { Nav as SecondaryNav } from 'cccisd-laravel-appdefs';
import logo from '../../../../../public/images/header-logo.png';

const AppDefs = window.cccisd.appDefs;
const Fortress = window.cccisd.fortress;

const NavBar = ({ className, showSecondaryNav }) => {
    return (
        <>
            <Header
                className={className}
                logo={
                    <img src={logo} height="50px" style={{ marginTop: '-15px' }} alt="egto logo" />
                }
            />
            {showSecondaryNav && Fortress.auth() && (
                <div style={{ backgroundColor: '#2a3f49' }}>
                    <SecondaryNav
                        className={className}
                        navs={AppDefs.navs}
                        routes={AppDefs.routes}
                    />
                </div>
            )}
        </>
    );
};

NavBar.defaultProps = {
    className: 'container',
    showSecondaryNav: true,
};

NavBar.propTypes = {
    className: PropTypes.string,
    showSecondaryNav: PropTypes.bool,
};

export default NavBar;
