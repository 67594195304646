import React from 'react';
import PropTypes from 'prop-types';
import { Report, Page } from 'cccisd-laravel-report';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
import widgets from '../widgets.js';
import ReportHeader from '../ReportHeader';
import filterFields from '../filterFields.js';
import style from './style.css';

const Component = props => {
    const { table } = props.widgets;

    if (!props.reportDataLoaded) {
        return <div>Loading...</div>;
    }

    return (
        // Margin is needed when report is displayed in flow player. (.form-group bootstrap class not working?)
        <div style={{ marginTop: '1em' }} className={style.reportWrapper}>
            <Report downloadFilename="eGTO_Step_4_Comparison">
                <Page>
                    <ReportHeader settings={props.settings} />
                    {table}
                </Page>
            </Report>
        </div>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgets: PropTypes.object,
    reportDataLoaded: PropTypes.bool,
};

Component.defaultProps = {};

export default reportTemplatePlayer({
    getWidgets: props =>
        widgets.map(widget => {
            return {
                ...widget,
                widgetProps: {},
            };
        }),
    getFilterFields: props =>
        filterFields.map(field => {
            if (field.name === 'intervention') {
                return {
                    ...field,
                    initialValue: props.interventionList.slice(0, 3),
                };
            }
            return field;
        }),
})(Component);
