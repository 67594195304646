import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, CccisdFieldWrapper } from 'cccisd-formik';
import { MultiSelect } from 'react-multi-select-component';
import style from './style.css';

const InterventionsSelect = props => {
    const reduxStore = useSelector(state => state.app.egto);
    const [interventionList, setInterventionList] = useState([]);

    useEffect(() => {
        if (reduxStore.step3ReportDataLoaded) {
            setInterventionList(
                reduxStore.step3InterventionList.map((option, index) => {
                    return {
                        ...option,
                        disabled: index >= 3,
                    };
                })
            );
        }
    }, [reduxStore.step3ReportDataLoaded]);

    const maxOptions = 3;

    const handleOnChange = (value, form) => {
        form.setFieldValue('intervention', value);
        /* Disable options if max options selected */
        const newOptions = [];
        if (value.length === maxOptions) {
            interventionList.forEach(option => {
                newOptions.push({
                    ...option,
                    disabled: !value.map(i => i.label).includes(option.label),
                });
            });
            setInterventionList(newOptions);
        } else {
            setInterventionList(reduxStore.step3InterventionList);
        }
    };

    if (!reduxStore.step3ReportDataLoaded) {
        return <div />;
    }

    return (
        // Margin is needed when report is displayed in flow player.
        <div style={{ marginBottom: '1em' }}>
            <Field name="intervention" label="Intervention" enableReinitialize>
                {({ field, form }) => {
                    // console.log(field, form);
                    return (
                        <CccisdFieldWrapper field={field} form={form} label="Intervention">
                            <MultiSelect
                                options={interventionList}
                                hasSelectAll={false}
                                disableSearch
                                value={
                                    form.values.intervention
                                        ? form.values.intervention
                                        : reduxStore.step3InterventionList
                                }
                                onChange={value => handleOnChange(value, form)}
                                labelledBy="intervention"
                                className={style.fixedWidth}
                                ClearSelectedIcon={<div />}
                            />
                        </CccisdFieldWrapper>
                    );
                }}
            </Field>
        </div>
    );
};

InterventionsSelect.propTypes = {
    settings: PropTypes.object,
};

export default InterventionsSelect;
