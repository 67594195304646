import React from 'react';
import PropTypes from 'prop-types';
import { Nav as SecondaryNav } from 'cccisd-laravel-appdefs';
import style from './style.css';
import IconPencil from 'cccisd-icons/pencil';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const AppDefs = window.cccisd.appDefs;
const Fortress = window.cccisd.fortress;

export default class MainLayout extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        className: PropTypes.string,
        showSecondaryNav: PropTypes.bool,
    };

    static defaultProps = {
        className: 'container-fluid',
        showSecondaryNav: false,
    };

    render() {
        return (
            <div className={style.wrapper}>
                <header className={style.header}>
                    <Header className={this.props.className} />
                </header>

                <div className={style.body}>
                    {this.props.showSecondaryNav && Fortress.auth() && (
                        <SecondaryNav
                            className={this.props.className}
                            navs={AppDefs.navs}
                            routes={AppDefs.routes}
                        />
                    )}
                    <div className={this.props.className}>
                        {this.props.children}
                        <p style={{ fontWeight: 'bold', marginTop: '0.5em' }}>
                            Use{' '}
                            <div className={style.buttonIcon}>
                                <IconPencil />
                            </div>{' '}
                            to update information about your VSO.
                        </p>
                    </div>
                </div>

                <div className={style.footer}>
                    <Footer className={this.props.className} />
                </div>
            </div>
        );
    }
}
