import Builder, { initialValues as builderInitialValues } from './Builder';
import Player from './Player';

export default {
    handle: 'step4',
    label: 'Step 4 Comparison',
    builderInitialValues,
    builder: Builder,
    player: Player,
};
