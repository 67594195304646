import React from 'react';
import style from './style.css';
import Step1Icon from './images/Step 1.svg';
import Step2Icon from './images/Step 2.svg';
import Step3Icon from './images/Step 3.svg';
import Step4Icon from './images/Step 4.svg';
import Step5Icon from './images/Step 5.svg';
import Step6Icon from './images/Step 6.svg';

export const stepColors = {
    1: '#6a6eaf',
    2: '#353863',
    3: '#c5002e',
    4: '#7f141d',
    5: '#1e818a',
    6: '#176168',
};

export default [
    {
        title: (
            <div>
                <img src={Step1Icon} alt="" className={style.icon} /> Step 1
            </div>
        ),
        content: <div>Conduct a needs assessment</div>,
        color: stepColors['1'],
        tooltip: (
            <div>
                <h5>Needs assessment</h5>
                <p>
                    Needs assessment is a systematic way to identify current conditions underlying the potential need
                    for a program or intervention and to identify related community resources.
                </p>
            </div>
        ),
    },
    {
        title: (
            <div>
                <img src={Step2Icon} alt="" className={style.icon} /> Step 2
            </div>
        ),
        content: <div>Develop goals & desired outcomes</div>,
        color: stepColors['2'],
        tooltip: (
            <div>
                <h5>Goal</h5>
                <p>
                    Goal is the overarching big picture of what an organization wants to achieve regarding improving the
                    lives of its target population. Goals reflect the anticipated impact in the future.
                </p>
                <h5>Desired outcomes</h5>
                <p>
                    Desired outcomes are specific changes in behaviors and determinants, such as knowledge, that are
                    expected as a result of a program. Well-written desired outcomes are specific, measurable,
                    achievable, realistic, and time-based (SMART). If the outcome is measured to be at a desirable point
                    before the start of the program, then a desired outcome could be to maintain that level.
                </p>
            </div>
        ),
    },
    {
        title: (
            <div>
                <img src={Step3Icon} alt="" className={style.icon} /> Step 3
            </div>
        ),
        content: <div>Select an evidence-based program or practice</div>,
        color: stepColors['3'],
        tooltip: (
            <div>
                <h5>Evidence-Based</h5>
                <p>
                    This term refers to a process in which experts, using commonly agreed-upon criteria for rating
                    research interventions, come to a consensus that evaluation research findings are credible and can
                    be substantiated. This process is sometimes referred to as “science-” or “research-based.”
                </p>
            </div>
        ),
    },
    {
        title: (
            <div>
                <img src={Step4Icon} alt="" className={style.icon} /> Step 4
            </div>
        ),
        content: <div>Assess program fit with your organization</div>,
        color: stepColors['4'],
        tooltip: (
            <div>
                <h5>Fit</h5>
                <p>
                    Fit is the overall compatibility between a program, the target population, the organization, and the
                    target community.
                </p>
            </div>
        ),
    },
    {
        title: (
            <div>
                <img src={Step5Icon} alt="" className={style.icon} /> Step 5
            </div>
        ),
        content: <div>Assess your capacity to deliver the program</div>,
        color: stepColors['5'],
        tooltip: (
            <div>
                <h5>Capacities</h5>
                <p>
                    Capacities are the resources (staff, skills, facilities, finances, and others) that an organization
                    needs to implement and sustain a program.
                </p>
            </div>
        ),
    },
    {
        title: (
            <div>
                <img src={Step6Icon} alt="" className={style.icon} /> Step 6
            </div>
        ),
        content: <div>Create an implementation work plan</div>,
        color: stepColors['6'],
        tooltip: (
            <div>
                <h5>Work plan</h5>
                <p>
                    Work plan is the organized, formal documentation of components and tasks necessary to implement a
                    program, broken down by resources, personnel, delivery dates, and accomplishments; the work plan
                    specifies who will do what, when, where, and how.
                </p>
            </div>
        ),
    },
];
