import React, { useState, useEffect } from 'react';
import {
    useGetStepsAssignmentQuery,
    useStep3ListVarsQuery,
    useStep5ListVarsQuery,
    useStep6ListVarsQuery,
    useStep2AlertsQuery,
    useStep3AlertsQuery,
    useStep4AlertsQuery,
    useStep5AlertsQuery,
} from 'js/queries';
import { filterStep5Proceed, objToArray } from './helpers.js';
import _flatten from 'lodash/flatten';
import _uniq from 'lodash/uniq';
import Loader from 'cccisd-loader';

const useGetLogicModelData = ({ deployment, metricspawn }) => {
    const [loading, setLoading] = useState(true);

    const stepsAssignment = useGetStepsAssignmentQuery(
        `stepsAssignment ${deployment.deploymentId}`,
        metricspawn.pawnId,
        deployment.deploymentId
    );

    const thereIsStep2Progress =
        stepsAssignment?.data?.data?.flows.deployment.assignment.surveyList[1].flow.flowProgress.status;
    const thereIsStep3Progress =
        stepsAssignment?.data?.data?.flows.deployment.assignment.surveyList[2].flow.flowProgress.status;
    const thereIsStep4Progress =
        stepsAssignment?.data?.data?.flows.deployment.assignment.surveyList[3].flow.flowProgress.status;
    const thereIsStep5Progress =
        stepsAssignment?.data?.data?.flows.deployment.assignment.surveyList[4].flow.flowProgress.status;

    const step2AlertsQuery = useStep2AlertsQuery(
        `step2Alerts ${deployment.deploymentId}`,
        metricspawn.pawnId,
        deployment.deploymentId,
        thereIsStep2Progress
    );
    const step3AlertsQuery = useStep3AlertsQuery(
        `step3Alerts ${deployment.deploymentId}`,
        metricspawn.pawnId,
        deployment.deploymentId,
        thereIsStep3Progress
    );
    const step4AlertsQuery = useStep4AlertsQuery(
        `step4Alerts ${deployment.deploymentId}`,
        metricspawn.pawnId,
        deployment.deploymentId,
        thereIsStep4Progress
    );

    const step5AlertsQuery = useStep5AlertsQuery(
        `step5Alerts ${deployment.deploymentId}`,
        metricspawn.pawnId,
        deployment.deploymentId,
        thereIsStep5Progress
    );

    const step3ListVarsQuery = useStep3ListVarsQuery(
        `step3ListVars ${deployment.deploymentId}`,
        metricspawn.pawnId,
        deployment.deploymentId
    );

    const step5ListVarsQuery = useStep5ListVarsQuery(
        `step5ListVars ${deployment.deploymentId}`,
        metricspawn.pawnId,
        deployment.deploymentId
    );

    const step6ListVarsQuery = useStep6ListVarsQuery(
        `step6ListVars ${deployment.deploymentId}`,
        metricspawn.pawnId,
        deployment.deploymentId
    );

    useEffect(() => {
        setLoading(true);
        (async () => {
            await step3ListVarsQuery.refetch();
            await step5ListVarsQuery.refetch();
            await step6ListVarsQuery.refetch();

            await step2AlertsQuery.refetch();
            await step3AlertsQuery.refetch();
            await step4AlertsQuery.refetch();
            await step5AlertsQuery.refetch();
            setLoading(false);
        })();
    }, []);

    const isLoading =
        step3ListVarsQuery.isLoading ||
        step5ListVarsQuery.isLoading ||
        step6ListVarsQuery.isLoading ||
        stepsAssignment.isLoading ||
        step2AlertsQuery.isLoading ||
        step3AlertsQuery.isLoading ||
        step4AlertsQuery.isLoading ||
        step5AlertsQuery.isLoading;

    const allQueryDataReturned = [
        step3ListVarsQuery,
        step5ListVarsQuery,
        step6ListVarsQuery,
        stepsAssignment,
        step2AlertsQuery,
        step3AlertsQuery,
        step4AlertsQuery,
        step5AlertsQuery,
    ].every(x => x.data?.loading === false);

    if (loading || isLoading || !allQueryDataReturned) {
        return <Loader loading />;
    }

    // console.log(step6ListVarsQuery.data.data.roles.metricspawn.assignmentProgress);

    const outcomes = step3ListVarsQuery.data?.data.roles.metricspawn.assignmentProgress.selectedOutcomes;
    const interventionsProceedStep3 = step3ListVarsQuery.data?.data.roles.metricspawn.assignmentProgress.proceed;
    const interventionsProceedStep5 = step5ListVarsQuery.data?.data.roles.metricspawn.assignmentProgress.proceed;

    const s3goals = step3ListVarsQuery.data?.data.roles.metricspawn.assignmentProgress.selectedGoals;

    const filteredGoals = filterStep5Proceed(
        s3goals,
        's3_',
        '_selected_goals',
        interventionsProceedStep5,
        thereIsStep5Progress
    );

    const selectedGoalsList = objToArray(filteredGoals)
        .filter(x => x !== 'MetricsDevTags')
        .filter(Boolean)
        .map(x => JSON.parse(x));

    const step3FilteredOutcomes = filterStep5Proceed(
        outcomes,
        's3_',
        '_selected_outcomes',
        interventionsProceedStep5,
        thereIsStep5Progress
    );
    const interventions = step3ListVarsQuery.data.data.roles.metricspawn.assignmentProgress.interventions;

    const filteredInterventions = objToArray(
        filterStep5Proceed(interventions, 'step3_', '_name', interventionsProceedStep5, thereIsStep5Progress)
    )
        .filter(x => x !== 'MetricsDevTags')
        .filter(Boolean);

    const getStep3Outcomes = () => {
        let outcomesList = objToArray(step3FilteredOutcomes)
            .filter(x => x !== 'MetricsDevTags')
            .filter(Boolean);

        outcomesList = _flatten(outcomesList.map(x => JSON.parse(x)));
        return outcomesList;
    };

    const step6Outcomes = step6ListVarsQuery.data.data.roles.metricspawn.assignmentProgress;
    const step6DesiredOutcomes = objToArray(step6Outcomes.desiredOutcomes)
        .filter(x => x !== 'MetricsDevTags')
        .filter(Boolean);
    const step6EvalMeasures = objToArray(step6Outcomes.outcomesEvalMeasures)
        .filter(x => x !== 'MetricsDevTags')
        .filter(Boolean);
    const monitorPopulationChanges = step6Outcomes.monitorPopulationChanges;

    // New functions
    const getDesiredOutcomes = interventionId => {
        const s3Outcomes = outcomes;
        const s6Outcomes = step6Outcomes.desiredOutcomes;
        const s6OutcomesList = [];
        const s3OutcomesList = JSON.parse(s3Outcomes['s3_' + interventionId + '_selected_outcomes']).map(
            (outcome, i) => ({ ['outcome_' + (i + 1)]: outcome })
        );

        for (let x = 1; x <= 9; x++) {
            if (s6Outcomes['s6_' + interventionId + '_desired_outcome_' + x]) {
                s6OutcomesList.push({
                    ['outcome_' + x]: s6Outcomes['s6_' + interventionId + '_desired_outcome_' + x],
                });
            }
        }
        if (s6OutcomesList.length > 0) {
            return s6OutcomesList;
        }
        return s3OutcomesList;
    };

    const getInterventionProceedIds = () => {
        const step3ProceedKeys = Object.keys(interventionsProceedStep3).filter(key => {
            return interventionsProceedStep3[key] === '0';
        });
        return step3ProceedKeys
            .filter(key => interventionsProceedStep5['s5' + key.slice(2)] !== '99')
            .map(interventionKey => interventionKey.slice(3, 5));
    };

    const getProcessEvalMeasures = interventionId => {
        const metrics = objToArray(step6Outcomes.implementationQuality)
            .filter(x => x !== 'MetricsDevTags')
            .filter(Boolean);
        if (metrics.some(x => x)) {
            let measures = [];
            if (step6Outcomes.implementationQuality['s6_' + interventionId + '_intervention_reach_yn'] === '1') {
                measures.push('Intervention Reach');
            }
            if (step6Outcomes.implementationQuality['s6_' + interventionId + '_implementation_fidelity_yn'] === '1') {
                measures.push('Implementation Fidelity');
            }
            if (step6Outcomes.implementationQuality['s6_' + interventionId + '_participant_perceptions_yn'] === '1') {
                measures.push('Participant Perceptions');
            }
            if (step6Outcomes.implementationQuality['s6_' + interventionId + '_staff_perceptions_yn'] === '1') {
                measures.push('Staff Perceptions');
            }
            if (step6Outcomes.implementationQuality['s6_' + interventionId + '_adherence_to_workplan_yn'] === '1') {
                measures.push('Adherence to Workplan');
            }
            if (step6Outcomes.implementationQuality['s6_' + interventionId + '_quality_other_yn'] === '1') {
                measures.push('Other');
            }
            return measures;
        }
    };

    const getOutcomeEvalMeasures = interventionId => {
        const evalMeasuresList = [];
        for (let x = 1; x <= 9; x++) {
            if (
                step6Outcomes.outcomesEvalMeasures['s6_' + interventionId + '_desired_outcome_' + x + '_eval_measure']
            ) {
                evalMeasuresList.push({
                    ['outcome_' + x]:
                        step6Outcomes.outcomesEvalMeasures[
                            's6_' + interventionId + '_desired_outcome_' + x + '_eval_measure'
                        ],
                });
            }
        }
        return evalMeasuresList;
    };

    const interventionsSorted = getInterventionProceedIds().map(id => {
        return {
            key: id,
            title: interventions['step3_' + id + '_name'],
            goals: JSON.parse(s3goals['s3_' + id + '_selected_goals']),
            outcomes: getDesiredOutcomes(id),
            processEvalMeasures: getProcessEvalMeasures(id),
            outcomeEvalMeasures: getOutcomeEvalMeasures(id),
        };
    });

    const uniqueGoalList = _uniq(selectedGoalsList.flat());

    const getOutcomesByGoal = interventionDataList => {
        const outcomesList = [];
        // Iterate through each intervention associated with this Goal.
        // Get all unique outcomes associated with those interventions.
        interventionDataList.forEach(intervention => {
            intervention.outcomes.forEach(outcomeObj => {
                const outcomeInList = outcomesList.find(outcome => outcome.title === Object.values(outcomeObj)[0]);
                const outcomeEvalMeasures = intervention.outcomeEvalMeasures.find(
                    measure => measure[Object.keys(outcomeObj)[0]]
                );

                if (!outcomeInList) {
                    outcomesList.push({
                        key: Object.keys(outcomeObj)[0],
                        title: Object.values(outcomeObj)[0],
                        interventions: [{ id: intervention.key, title: intervention.title }],
                        processEvalMeasures: {
                            [intervention.key]: intervention.processEvalMeasures,
                        },
                        outcomeEvalMeasures: outcomeEvalMeasures ? Object.values(outcomeEvalMeasures) : null,
                    });
                } else {
                    if (!outcomeInList.interventions.find(x => x.id === intervention.key)) {
                        outcomeInList.interventions.push({
                            id: intervention.key,
                            title: intervention.title,
                        });
                    }
                    if (!outcomeInList.processEvalMeasures[intervention.key]) {
                        outcomeInList.processEvalMeasures[intervention.key] = intervention.processEvalMeasures;
                    }
                    const currOutcomeEvalMeasure = intervention.outcomeEvalMeasures.find(
                        measure => measure[Object.keys(outcomeObj)[0]]
                    );
                    if (
                        currOutcomeEvalMeasure &&
                        !outcomeInList.outcomeEvalMeasures?.includes(currOutcomeEvalMeasure)
                    ) {
                        outcomeInList.outcomeEvalMeasures?.push(Object.values(currOutcomeEvalMeasure)[0]);
                    }
                }
            });
        });

        return outcomesList;
    };

    const outcomesByGoal = uniqueGoalList.map(goal => {
        const interventionsByGoal = interventionsSorted.filter(data => data.goals.includes(goal));
        return {
            title: goal,
            interventionData: interventionsByGoal,
            outcomes: getOutcomesByGoal(interventionsByGoal),
        };
    });

    return {
        stepsAssignment,
        thereIsStep2Progress,
        thereIsStep3Progress,
        thereIsStep4Progress,
        thereIsStep5Progress,
        step2AlertsQuery,
        step3AlertsQuery,
        step4AlertsQuery,
        step5AlertsQuery,
        loading,
        isLoading,
        allQueryDataReturned,
        selectedGoalsList,
        filteredInterventions,
        getStep3Outcomes,
        step3Outcomes: getStep3Outcomes(),
        step6Outcomes,
        step6DesiredOutcomes,
        step6EvalMeasures,
        monitorPopulationChanges,
        interventionsSorted,
        outcomesByGoal,
    };
};

export default useGetLogicModelData;
