import _orderBy from 'lodash/orderBy';

export const sortProblemList = (savedProblems, queryData) => {
    const updatedProblemList = [];
    const deploymentIds = savedProblems.map(p => p.deploymentId);
    deploymentIds.forEach(id => {
        updatedProblemList.push(queryData.find(apProblem => apProblem.deploymentId === id));
    });
    queryData.forEach(apProblem => {
        if (!updatedProblemList.map(p => p.deploymentId).includes(apProblem.deploymentId)) {
            updatedProblemList.push(apProblem);
        }
    });
    return updatedProblemList;
};

export const sortProblemsByPriority = problemList => {
    const giveProblemsPriorityVals = () => {
        const problemListWithPriorityVals = problemList.map(p => {
            const priorityMetric = p.problem.s1_problem_priority;
            if (!p.priority && priorityMetric) {
                if (priorityMetric === 'High') {
                    return { ...p, priority: 1 };
                }
                if (priorityMetric === 'Medium') {
                    return { ...p, priority: 2 };
                }
            }
            return { ...p, priority: 3 };
        });
        return problemListWithPriorityVals;
    };
    const probsWithPriorityVal = giveProblemsPriorityVals();
    const orderedProblems = _orderBy(probsWithPriorityVal, ['priority', 'assignmentProgressId'], ['asc', 'desc']);

    return orderedProblems;
};

export const filterArchivedProblems = data => {
    if (data.length > 0) {
        const filteredProblems = data.filter(p => p.archived.problem_archived !== '1');
        return filteredProblems;
    }
    return data;
};

export const swapElement = (array, from, to) => {
    array.splice(to, 0, array.splice(from, 1)[0]);
};
