import Builder, { initialValues as builderInitialValues } from './Builder';
import Player from './Player';

export default {
    handle: 'step5',
    label: 'Step 5 Comparison',
    builderInitialValues,
    builder: Builder,
    player: Player,
};
