import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import axios from 'cccisd-axios';
import IconMove from 'cccisd-icons/move';
import Loader from 'cccisd-loader';
import ProblemCard from './ProblemCard';
import AddAProblem from './AddAProblem';
import style from './style.css';
import { useMetricspawnQuery, useAssignmentProgress } from 'js/queries';
import { sortProblemsByPriority, filterArchivedProblems, swapElement, sortProblemList } from './helpers.js';
import FlagIcon from './images/Flag Icon.svg';

const Fortress = window.cccisd.fortress;
const Boilerplate = window.cccisd.boilerplate;

const ProblemBox = props => {
    const [problems, setProblems] = useState([]);

    const metricspawnQuery = useMetricspawnQuery();
    const metricspawn = metricspawnQuery?.data?.descendantRoles?.metricspawn?.pawn;
    const assignmentProgressQuery = useAssignmentProgress('assignmentProgress', metricspawn?.pawnId);

    useEffect(() => {
        const savedProblemOrder = metricspawnQuery?.data?.fields?.problemOrder;
        if (savedProblemOrder) {
            const problemOrderList = JSON.parse(savedProblemOrder);

            const assignmentProgressListSorted = sortProblemList(problemOrderList, assignmentProgressQuery.data);
            setProblems(filterArchivedProblems(assignmentProgressListSorted));
            return;
        }
        setProblems(filterArchivedProblems(sortProblemsByPriority(assignmentProgressQuery.data)));
    }, [
        metricspawnQuery.isLoading,
        metricspawnQuery.data,
        assignmentProgressQuery.isLoading,
        assignmentProgressQuery.data,
    ]);

    const updateProblemOrder = async pOrder => {
        let response = await axios.put(
            Boilerplate.route('api.nexus.group.update', {
                group: Fortress.user.acting.group.id,
            }),
            {
                problemOrder: pOrder,
            }
        );
        return response;
    };

    const onDragEnd = result => {
        const { destination, source } = result;
        if (!destination) {
            return;
        }
        swapElement(problems, source.index, destination.index);
        updateProblemOrder(problems);
    };

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? '#eee' : '#fff',
        overflow: 'auto',
    });

    const getItemStyle = isDragging => ({
        userSelect: 'none',
        background: isDragging ? '#f0f0f0' : '',
    });

    const isLoading = metricspawnQuery.isLoading || assignmentProgressQuery.isLoading;

    const allQueryDataReturned = metricspawnQuery.data && assignmentProgressQuery.data;

    if (isLoading) {
        return <Loader loading />;
    }

    if (allQueryDataReturned) {
        return (
            <>
                <div className={style.container}>
                    <div
                        className={style.problemBox}
                        style={props.orgTeamMemberEditingDisabled ? {} : { borderBottom: 'none' }}
                    >
                        {problems < 1 ? (
                            <div className={style.emptyProblemList}>
                                <div className={style.emptyProblemWarning}>
                                    <div className={style.leftProblemWarning}>
                                        <img src={FlagIcon} alt="" className={style.icon} />
                                    </div>
                                    <div className={style.rightProblemWarning}>
                                        <div className={style.emptyProblemTitle}>
                                            <h3>
                                                <span className={style.h3}>How to get started</span>
                                            </h3>
                                        </div>

                                        <div className={style.emptyProblemText}>
                                            <div className={style.problemTextBlock}>
                                                <p>
                                                    e-GTO guides you through all the tasks needed to establish and
                                                    support intervention implementation.
                                                </p>
                                            </div>
                                            <div className={style.problemTextBlock}>
                                                <p style={{ fontWeight: 'bold' }}>
                                                    {`To start the process, click "Add a problem" below
                                                and follow the on-screen instructions.`}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided, snapshot) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            style={getListStyle(snapshot.isDraggingOver)}
                                        >
                                            {problems.map((ap, index) => {
                                                return (
                                                    <Draggable
                                                        draggableId={ap.deploymentId + ''}
                                                        index={index}
                                                        key={ap.deploymentId}
                                                        isDragDisabled={props.orgTeamMemberEditingDisabled}
                                                    >
                                                        {(dragProvided, dragSnapshot) => (
                                                            <div
                                                                ref={dragProvided.innerRef}
                                                                {...dragProvided.draggableProps}
                                                                role="presentation"
                                                                key={'key' + index}
                                                                className={style.tab}
                                                            >
                                                                <div
                                                                    className={
                                                                        props.orgTeamMemberEditingDisabled
                                                                            ? style.dragHandleDisabled
                                                                            : style.dragHandle
                                                                    }
                                                                    {...dragProvided.dragHandleProps}
                                                                    style={getItemStyle(
                                                                        dragSnapshot.isDragging,
                                                                        dragProvided.draggableProps.style
                                                                    )}
                                                                >
                                                                    <IconMove />
                                                                </div>

                                                                <ProblemCard
                                                                    step2Alerts={ap.step2Alerts}
                                                                    problem={ap.problem}
                                                                    deployment={ap}
                                                                    metricspawn={metricspawn}
                                                                    assignmentProgressQuery={assignmentProgressQuery}
                                                                    orgTeamMemberEditingDisabled={
                                                                        props.orgTeamMemberEditingDisabled
                                                                    }
                                                                />
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            })}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        )}
                    </div>
                    {!props.orgTeamMemberEditingDisabled && (
                        <AddAProblem
                            refetch={assignmentProgressQuery.refetch}
                            orgTeamMemberEditingDisabled={props.orgTeamMemberEditingDisabled}
                        />
                    )}
                </div>
            </>
        );
    }
};

export default ProblemBox;
