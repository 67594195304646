import React from 'react';
import IconNotification from 'cccisd-icons/notification';

const WarningCard = props => {
    return (
        <div
            style={{
                width: '75%',
                margin: '1em auto',
                border: '3px solid #353863',
                color: '#353863',
                display: 'flex',
                background: '#fff',
            }}
        >
            <div
                style={{
                    verticalAlign: 'middle',
                    padding: '0.5em',
                    background: '#353863',
                    color: '#fff',
                    fontWeight: 'bold',
                }}
            >
                <IconNotification />
            </div>
            <div style={{ padding: '1em' }}>
                <p>
                    There are existing incomplete problem steps for{' '}
                    <span style={{ fontWeight: 'bold' }}>{props.problem.s1_problem_name}</span>. Make sure you create{' '}
                    <span style={{ fontWeight: 'bold' }}>Outcomes</span> in{' '}
                    <span style={{ fontWeight: 'bold' }}>Step 2</span>,{' '}
                    <span style={{ fontWeight: 'bold' }}>Interventions</span> in{' '}
                    <span style={{ fontWeight: 'bold' }}>Step 3</span>, and{' '}
                    <span style={{ fontWeight: 'bold' }}>Proceed</span> with those Interventions in{' '}
                    <span style={{ fontWeight: 'bold' }}>Steps 3</span>, and{' '}
                    <span style={{ fontWeight: 'bold' }}>5</span>.
                </p>
                <p>
                    You can <span style={{ fontWeight: 'bold' }}>Archive</span> a Problem if you {`don't`} want it to
                    appear in your Logic Model.
                </p>
            </div>
        </div>
    );
};

export default WarningCard;
