import React from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import Tooltip from 'cccisd-tooltip';
import style from './style.css';

const StepBox = props => {
    return (
        <div className={style.container} style={{ borderColor: props.color }}>
            <Tooltip title={props.tooltip}>
                <div className={style.header} style={{ backgroundColor: props.color }}>
                    {props.title}
                </div>
                <div className={style.content}>{props.content}</div>
            </Tooltip>
        </div>
    );
};

StepBox.propTypes = {
    title: oneOfType([PropTypes.string, PropTypes.node]),
    content: oneOfType([PropTypes.string, PropTypes.node]),
    tooltip: oneOfType([PropTypes.string, PropTypes.node]),
    color: PropTypes.string,
};

StepBox.defaultProps = {
    title: 'Step 1',
    content: 'Conduct a Needs Assessment',
    color: '#353863',
};

export default StepBox;
